const iconMap = {
  Id_Props_AltarOfSacrifice: 'Revive',
  Id_Extraction_Rope: 'extractionStairs',
  BP_PedestalPortal_C_1: 'extractionPortal',
  BP_DungeonEscape_C_1: 'extractionStairs',
  BP_FixedElevator_C_1: 'extractionElevator',
  BP_FixedStairEscape_C_1: 'extractionStairs',
  BP_FixedStairEscape_C_0: 'extractionStairs',
  BP_CaveExit_C_0: 'extractionStairs',
  BP_DungeonDown_C_1: 'downStairs',
  BP_FixedStairDown_C_1: 'downStairs',
  Id_Extraction_Raft: 'extractionRaft',
  Id_Props_Statue03: 'Power',
  Id_Props_Statue02: 'Protection',
  Id_Props_Statue01: 'Health',
  Id_Props_FlatChestLarge: 'chestFlatBase',
  chestFlatOrange: 'chestFlatOrange',
  chestFlatRed: 'chestFlatRed',
  chestGoldenBase: 'chestGoldenBase',
  chestGoldenRed: 'chestGoldenRed',
  Id_Props_MarvelousChest: 'chestMarvelousBase',
  chestMarvelousRed: 'chestMarvelousRed',
  Id_Props_SimpleChestLarge: 'chestOakBase',
  Id_Props_ChestLarge: 'chestFlatOrange',
  Id_Props_ChestMedium: 'chestOakOrange',
  Id_Props_WoodChestLarge: 'chestReinforcedBase',
  chestReinforcedRed: 'chestReinforcedRed',
  chestReinforcedOrange: 'chestReinforcedOrange',
  Id_Props_OrnateChestLarge: 'chestLionsBase',
  Id_Props_ChestSpecial: 'chestLionsRed',
  Id_Props_ChestSpecial_N2: 'chestLionsRed',
  Id_Props_OrnateChestSmall: 'chestOrnateBase',
  Id_Props_OrnateChestMedium: 'chestOrnateBase',
  Id_Props_OrnateChestMedium_N2: 'chestOrnateBase',
  chestOrnateRed: 'chestOrnateRed',
  OrnateChest: 'chestOrnateBase',
  Id_Props_OrnateChestLarge_N2: 'chestOrnateBase',
  chestOrnateOrange: 'chestOrnateOrange',
  Id_Props_ChestSmall: 'chestOakOrange',
  chestOakBase: 'chestOakBase',
  chestOakRed: 'chestOakRed',
  Id_Props_Ore_CobaltOreRandom: 'cobaltOre2',
  Id_Props_Ore_CobaltOre: 'cobaltOre',
  Id_Props_Ore_GoldOre: 'goldOre',
  Id_Props_Ore_RubysilverOre: 'rubyOre',
  Id_Props_Statue04: 'Speed',
  DCPlayerStart: 'playerSpawn',
};

const containsMap = {
  Id_Props_FlameSpout: 'miscTrap',
  Id_Props_WoodenBarricate: 'miscDoor',
  DCPlayerStart_0: 'playerSpawn',
  DCPlayerStart_1: 'playerSpawn',
  BP_DungeonEscape_C_0: 'extractionStairs',
  BP_FixedStairDown_C_0: 'downStairs',
  CobaltOreRandom: 'cobaltOre2',
  SkeletonBones: 'chestSkeletonCorpse',
  SpiderPot: 'mob',
  Pot: 'miscPot',
  PressurePlate: 'miscPressurePlate',
  LockPick: 'miscLockpick',
  Lockpick: 'miscLockpick',
  Bandage: 'miscBandage',
  Coin: 'misc',
  Portcullis: 'miscDoor',
  Roaster: 'miscTorch',
  Torch: 'miscTorch',
  Candle: 'miscTorch',
  Crate: 'miscCrate',
  Coffin_06: 'chestRoyalCoffin',
  Coffin_05: 'chestCoffin',
  Coffin_04: 'chestCoffin',
  Coffin_03: 'chestCoffin',
  Coffin_02: 'chestCoffin',
  Coffin_01: 'chestCoffin1',
  Id_Monster_SpectralKnight: 'mobBossMain',
  Hoard: 'treasurePile',
  Barrel: 'miscBarrel',
  StoneTomb: 'stoneTomb', //change this eventually
  DeathBeetle: 'mobFlying1',
  GiantDragonfly: 'mobFlying1',
  GiantBat: 'mobFlying1',
  DeathSkull: 'mobFlying1',
  Ranged: 'mobRanged1',
  Crossbowman: 'mobRanged1',
  Archer: 'mobRanged1',
  Bolas: 'mobRanged1',
  Mage: 'mobRanged3',
  Chest: 'chestOakBase',
  SkullKey: 'skullKey',
  LootDrop: 'miscTrinket',
  FrostStoneOre: 'frostOre',
  IronOre: 'ironOre',
  CopperOre: 'copperOre',
  Door: 'miscDoor',
  Lever: 'miscLever',
  WallSpike: 'miscTrap',
  FloorSpikes: 'miscTrap',
  TrackAxe: 'miscTrap',
  Wraith: 'mobMiniBossMain',
  GiantWorm: 'mobMiniBossMain',
  Cockatrice: 'mobMiniBossMain',
  CentaurDemon: 'mobMiniBossMain',
  DemonBerserker: 'mobMiniBossMain',
  SkeletonChampion: 'mobMiniBossMain',
  GiantCentipede: 'mobMiniBossMain',
  Yeti: 'mobMiniBossMain',
  IceHarpy: 'mobMiniBossMain',
  FrostGiantBerserker: 'mobMiniBossMain',
  FrostGiantShielder: 'mobMiniBossMain',
  Wendigo: 'mobMiniBossMain',
  FrostImp: 'mobMiniBossMain',
  FrostDemon: 'mobMiniBossMain',
  CaveTroll: 'mobBossMain',
  Cyclops: 'mobBossMain',
  SkeletonWarlord: 'mobBossMain',
  Lich: 'mobBossMain',
  UndeadKnight: 'mobBossMain',
  RuinsGolem: 'mobBossMain',
  GhostKing: 'mobBossMain',
  FrostWyvern: 'mobBossMain',
  Flower: 'miscFlower',
  Wardweed_: 'miscFlower',
  Lifeleaf: 'miscFlower',
};

const getImageId = (iconValue) => {
  for (const [key, value] of Object.entries(iconMap)) {
    if (iconValue.startsWith(key)) return value;
  }

  for (const [key, value] of Object.entries(containsMap)) {
    if (iconValue.includes(key)) return value;
  }

  if (iconValue.startsWith('Id_Monster')) return 'mob';
  if (iconValue.startsWith('MN_')) return 'moduleName';

  return 'misc';
};

export default getImageId;
