import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, ImageOverlay, useMap } from 'react-leaflet';
import ReactLeafletPixiOverlay from 'react-leaflet-pixi-overlay';
import L from 'leaflet';
import { FloatButton, Button } from 'antd';
import PropTypes from 'prop-types';
import {
  ExpandOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

const MapDisplay = ({
  curMap,
  setCurMap,
  iconsToPlot,
  zoom,
  isCollapsed,
  setIsCollapsed,
  setZoom,
  setIconsToPlot,
  mapIconScale,
  setMapIconScale,
  filteredMaps,
  setFilteredMaps,
  setMapName,
  setMapType,
  updateVersion,
  setUpdateVersion,
  moduleIconScale,
  setModuleIconScale,
  iconStates,
  setIconStates,
}) => {
  const customCRS = L.CRS.Simple;
  customCRS.transformation = new L.Transformation(2, -1000, -2, 1000);

  const bounds = [
    [1440 - 10000, 580 - 10000],
    [1440 + 10000, 580 + 10000],
  ];
  const handlePrevClick = () => {
    filteredMaps.forEach((map, index) => {
      if ('/data/maps/' + map === curMap) {
        if (index - 1 < 0) {
          setMapName(filteredMaps[filteredMaps.length - 1]);
        } else {
          setMapName(filteredMaps[index - 1]);
        }
      }
    });
  };

  const handleNextClick = () => {
    filteredMaps.forEach((map, index) => {
      if ('/data/maps/' + map === curMap) {
        if (index + 1 >= filteredMaps.length) {
          setMapName(filteredMaps[0]);
        } else {
          setMapName(filteredMaps[index + 1]);
        }
      }
    });
  };


  useEffect(() => {
    setUpdateVersion(updateVersion + 1);
  }, [mapIconScale, moduleIconScale]);
  

  const MapUpdater = () => {
    const map = useMap();

    useEffect(() => {
      map.setZoom(zoom);
    }, [zoom, map]);

    useEffect(() => {
      const handleZoomEnd = () => {
        setZoom(map.getZoom());
      };

      map.on('zoomend', handleZoomEnd);

      return () => {
        map.off('zoomend', handleZoomEnd);
      };
    }, [map]);

    const maxWidth = 1920;

    const calculateMapSize = useCallback(() => {
      const minWidth = 1440;
      const rapidIncreaseThreshold = 1600;

      let mapWidth = Math.min(maxWidth, window.innerWidth * 2.0 + 5000);

      if (window.innerWidth < rapidIncreaseThreshold) {
        if (isCollapsed) {
          mapWidth = Math.max(minWidth, window.innerWidth * 1.0 + 4000);
        } else {
          mapWidth = Math.max(minWidth, window.innerWidth * 1.0 + 8000);
        }
      } else {
        if (isCollapsed) {
          mapWidth = Math.max(minWidth, window.innerWidth);
        } else {
          mapWidth = Math.max(minWidth, window.innerWidth + 2000);
        }
      }

      return mapWidth;
    }, [isCollapsed]);

    const fitMapToBounds = useCallback(() => {
      const contentCenter = [1440, calculateMapSize()];
      const contentWidth = 17000;
      const contentHeight = 17000;

      const bounds = [
        [
          contentCenter[0] - contentHeight / 2,
          contentCenter[1] - contentWidth / 2,
        ],
        [
          contentCenter[0] + contentHeight / 2,
          contentCenter[1] + contentWidth / 2,
        ],
      ];

      map.fitBounds(bounds);
    }, [calculateMapSize, map]);

    useEffect(() => {
      const handleResize = () => {
        fitMapToBounds();
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [fitMapToBounds]);

    return (
      <>
        <FloatButton.Group
          shape="circle"
          style={{ right: isCollapsed ? 16 : 416, zIndex: 90000000 }}
        >
          <FloatButton
            tooltip={<div>Toggle Sidebar</div>}
            icon={isCollapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            onClick={() => setIsCollapsed(!isCollapsed)}
          />
          <FloatButton
            tooltip={<div>Fit to Bounds</div>}
            icon={<ExpandOutlined />}
            onClick={fitMapToBounds}
          />
        </FloatButton.Group>
      </>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MapContainer
        center={[1800, 5500]}
        zoom={zoom}
        crs={customCRS}
        attributionControl={false}
        zoomControl={false}
        minZoom={-10}
        maxZoom={10}
        zoomSnap={0.01}
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: '#1E1C18',
          position: 'relative',
          marginLeft: '-6vw',
          marginTop: '-10vh',
        }}
      >
        <MapUpdater />
        <ImageOverlay
          url={curMap ? curMap : '?'}
          bounds={bounds}
          pane="tilePane"
        />
        <ReactLeafletPixiOverlay
          className="pixi-overlay"
          markers={iconsToPlot}
          pane="markerPane"
        />
      </MapContainer>
      {filteredMaps.length > 1 && (
        <>
          <Button
            style={{
              position: 'fixed',
              float: 'left',
              left: 216,
              color: 'white',
              cursor: 'pointer',
              zIndex: 1000,
            }}
            onClick={handlePrevClick}
          >
            <LeftOutlined />
          </Button>
          <Button
            style={{
              position: 'fixed',
              float: 'right',
              right: isCollapsed ? 16 : 416,
              color: 'white',
              cursor: 'pointer',
              zIndex: 1000,
            }}
            onClick={handleNextClick}
          >
            <RightOutlined />
          </Button>
        </>
      )}
    </div>
  );
};

MapDisplay.propTypes = {
  curMap: PropTypes.string.isRequired,
  setCurMap: PropTypes.func.isRequired,
  iconsToPlot: PropTypes.array.isRequired,
  setIsCollapsed: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  setIconsToPlot: PropTypes.func.isRequired,
  mapIconScale: PropTypes.number.isRequired,
  setMapIconScale: PropTypes.func.isRequired,
  filteredMaps: PropTypes.array.isRequired,
  setFilteredMaps: PropTypes.func.isRequired,
  setMapName: PropTypes.func.isRequired,
  setMapType: PropTypes.func.isRequired,
  updateVersion: PropTypes.number.isRequired,
  setUpdateVersion: PropTypes.func.isRequired,
  moduleIconScale: PropTypes.number.isRequired,
  setModuleIconScale: PropTypes.func.isRequired,
  iconStates: PropTypes.object.isRequired,
  setIconStates: PropTypes.func.isRequired,
};

export default MapDisplay;
