import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

// Import all images from the specified folder
const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};

const merchantImages = importAll(
  require.context('../../img/itemIcons', false, /\.(png|jpe?g|svg)$/),
);

const trueNameMap = {
  WendigoHoof: 'WendigosHoof',
  ExplosiveBottle: 'ExplosionBottle',
  AncientScroll: 'LargeScroll',
  HealingPotion: 'HealPotion01',
  GlowingBlueIceEyes: 'GlowingBludeIceEyes',
  WendigoSharpClaws: ' WendigosSharpClaws',
  Weapon: 'Armingsword_4001',
  Armor: 'DarkPlateArmor',
  SimpleGoldBangle: 'SM_Bangle_01b',
  GoldBand: 'SM_Bangle_01a',
  PatternedGoldBangle: 'SM_Bangle_01c',
  DottedGoldBangle: 'SM_Bangle_01d',
  TurqoiseGemBangle: 'SM_Bangle_01e',
  ShineBangle: 'SM_Bangle_02h',
  LeafGoldBangle: 'SM_Bangle_01j',
  SlimBangle: 'SM_Bangle_02d',
  GlimmerBangle: 'SM_Bangle_02g',
  LightGoldBangle: 'SM_Bangle_01h',
  TripleGemBangle: 'SM_Bangle_01i',
  RingOfFinesse: 'RingofFinesse',
  Spellbook: 'SpellBook',
  FrostAmulet: 'BasicNecklace03',
  RingOfSurvival: 'BasicRing01',
  RingOfQuickness: 'BasicRing02',
  RingOfCourage: 'BasicRing03',
  RingOfResolve: 'BasicRing04',
  RingOfVitality: 'RingofVitality',
  RingOfWisdom: 'RingofWisdom',
  GoldCoinBag: 'CoinBag',
  WindLocket: 'BasicNecklace02',
  NecklaceOfPeace: 'Necklace01',
  RubysilverMysticVestments: 'MysticVestments_RS',
  GoldCoinChest: 'CoinChest_00',
};

const unsupportedRewards = ['Experience', 'Affinity', 'Additional Stash Tab'];

const rarities = {
  Junk: '0001',
  Poor: '1001',
  Common: '2001',
  Uncommon: '3001',
  Rare: '4001',
  Epic: '5001',
  Legendary: '6001',
  Unique: '7001',
};
// eslint-disable-next-line react/prop-types
function ItemAvatar({ name, width, isReward }) {
  if (isReward && unsupportedRewards.includes(name))
    return <div style={{ width: width, height: width }} />;

  name = name.replaceAll(' ', '');
  let rarity = null;
  const regex = /\(.*\)/;
  if (isReward) {
    if (regex.test(name)) {
      rarity = name.match(regex)[0].replace('(', '').replace(')', '');
      name = name.replace(regex, '');
    }
  }

  let trueName = trueNameMap[name];
  if (trueName) {
    name = trueName;
  }
  let imageSrc =
    merchantImages['Item_Icon_' + name.split(' ').join('') + '.png'];
  let count = 0;

  if (rarity && imageSrc === undefined) {
    imageSrc =
      merchantImages[
        'Item_Icon_' +
          name.split(' ').join('') +
          '_' +
          rarities[rarity] +
          '.png'
      ];
  }
  while (imageSrc === undefined && count <= 9) {
    imageSrc =
      merchantImages[
        'Item_Icon_' + name.split(' ').join('') + '_' + count + '001' + '.png'
      ];
    count++;
  }
  if (imageSrc === undefined) {
    return <div style={{ width: width, height: width }} />;
  }
  return (
    <img
      shape={'square'}
      src={imageSrc}
      alt={name}
      style={{ objectFit: 'contain', width: width, height: width }}
    />
  );
}

ItemAvatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ItemAvatar;
