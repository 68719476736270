// CalculatorCard.jsx
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Divider, Typography, Form, FloatButton } from 'antd';
import { DiscordOutlined } from '@ant-design/icons';
const { Title, Paragraph, Text } = Typography;

const CalculatorCard = ({
  title,
  formInputComponentList,
  outputObjects,
  descComponents,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth < 992);
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth < 768);
    setIsMediumScreen(window.innerWidth < 992);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <React.Fragment>
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        <FloatButton
          tooltip={<div>Discord server</div>}
          icon={<DiscordOutlined />}
          href="https://discord.gg/Czx6xMq5rN"
          target="_blank"
        />
      </FloatButton.Group>
      <Row className="center-contents">
        <Card style={{ maxWidth: 1200 }} bordered={false}>
          <Row>
            <Title level={3}>{title}</Title>
          </Row>
          <Row>
            {descComponents.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </Row>
          <Row>
            <Divider />
          </Row>
          <Row>
            <Col xs={24} sm={24} md={10} lg={11} xl={11}>
              <Form
                name={title}
                labelCol={isMediumScreen ? { span: 24 } : { span: 15 }}
                labelWrap
                layout={isMediumScreen ? 'vertical' : 'horizontal'}
                wrapperCol={isMediumScreen ? { span: 24 } : { span: 7 }}
                style={{ width: '100%' }}
                initialValues={{ remember: true }}
                autoComplete="off"
              >
                {formInputComponentList.map((component, index) => (
                  <React.Fragment key={index}>{component}</React.Fragment>
                ))}
              </Form>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={4}
              lg={2}
              xl={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isSmallScreen ? (
                <Divider style={{ width: '100%' }} />
              ) : (
                <Divider type="vertical" style={{ height: '100%' }} />
              )}
            </Col>
            <Col xs={24} sm={24} md={10} lg={11} xl={11}>
              {outputObjects.map((obj, index) => (
                <Paragraph strong key={index}>
                  {obj.outputLabel}: {obj.value}
                </Paragraph>
              ))}
            </Col>
          </Row>
        </Card>
      </Row>
    </React.Fragment>
  );
};

CalculatorCard.propTypes = {
  title: PropTypes.string.isRequired,
  formInputComponentList: PropTypes.arrayOf(PropTypes.node).isRequired,
  outputObjects: PropTypes.arrayOf(
    PropTypes.shape({
      outputLabel: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  descComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default CalculatorCard;
