/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Select,
  Typography,
  Row,
  Col,
  Switch,
  Divider,
  Slider,
  Collapse,
  Button,
  Menu,
  Input,
  Tabs,
  Radio,
  Flex,
  Checkbox,
  Tooltip,
} from 'antd';
const { Title, Text } = Typography;
const { Option } = Select;
const tagColors = {
  ruins: '#DAA520',
  crypt: '#927143',
  goblincave: '#34533d',
  iceabyss: '#202b58',
  icecavern: '#5b7a92',
  inferno: '#5f0505',
  any: '#5D3FD3',
};

const mapInfo = {
  crypt: { parentMap: 'The Ruins of Forgotten Castle', floor: '2nds' },
  inferno: { parentMap: 'The Ruins of Forgotten Castle', floor: '3rd' },
  goblincave: { parentMap: 'The Goblin Caves', floor: '1st' },
  iceabyss: { parentMap: 'The Frost Mountain', floor: '2nd' },
  icecavern: { parentMap: 'The Frost Mountain', floor: '1st' },
  ruins: { parentMap: 'The Ruins of Forgotten Castle', floor: '1st' },
};

const convertHexToRgba = (hex, alpha) => {
  // Convert hex to RGB
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  // Return RGBA
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const order = [
  'Ruins',
  'Crypt',
  'Inferno',
  'GoblinCave',
  'IceCavern',
  'IceAbyss',
];

const sortMapNames = (mapNames) => {
  // Create a map for quick lookup of the order index

  const orderMap = new Map(order.map((name, index) => [name, index]));

  // Sort the array using the orderMap
  return mapNames.sort((a, b) => {
    const aIndex = orderMap.get(a) !== undefined ? orderMap.get(a) : Infinity;
    const bIndex = orderMap.get(b) !== undefined ? orderMap.get(b) : Infinity;
    return aIndex - bIndex;
  });
};

const MapSelection = ({
  paths,
  mapType,
  setMapType,
  mapName,
  setMapName,
  isHr,
  setIsHr,
  filteredMaps,
}) => {
  const mapTypes = Array.from(new Set(paths.map((path) => path.split('/')[0])));
  const sortedMapNames = sortMapNames(mapTypes);

  const handleMapSelectionChange = (selectedMapPath) => {
    setMapName(selectedMapPath);
  };

  const handleMapTypeSelectionChange = (selectedMapType) => {
    setMapType(selectedMapType);
  };

  const onDungModeChanged = (e) => {
    setIsHr(e.target.value === 'hr');
  };
  return (
    <React.Fragment>
      <Divider style={{ marginTop: 0 }}>Map Selection</Divider>
      <div className="map-selection-button-group">
        {sortedMapNames.map((type) => {
          const mapId = type.split(' ').join('').toLowerCase();
          return (
            <Tooltip
              title={
                <span>
                  {`${mapInfo[mapId].floor} floor of `}
                  {/* <b> */}
                  <b style={{ color: '#76ABAE' }}>
                    {`${mapInfo[mapId].parentMap}`}
                  </b>
                </span>
              }
              placement={mapId.includes('ice') ? 'bottom' : 'top'}
            >
              <Button
                type={'primary'}
                ghost
                key={type}
                onClick={() => handleMapTypeSelectionChange(type)}
                className={mapId}
                style={{
                  background:
                    mapType === type
                      ? convertHexToRgba(tagColors[mapId], 1) // Fully opaque
                      : convertHexToRgba(tagColors[mapId], 0.3), // 30% opacity
                  color:
                    mapType === type
                      ? 'rgba(255, 255, 255, 1)'
                      : 'rgba(255, 255, 255, 0.4)',
                  borderColor:
                    mapType === type
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(255, 255, 255, 0.1)',
                }}
              >
                {type.replace(/([A-Z])/g, ' $1').trim()}
              </Button>
            </Tooltip>
          );
        })}
      </div>
      <Row style={{ marginTop: 16 }}>
        <Col span={8}>
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            Map Variant
          </div>
        </Col>
        <Col span={16}>
          <Select
            value={mapName}
            style={{ width: '150px' }}
            placeholder="Select Map"
            onChange={handleMapSelectionChange}
          >
            {filteredMaps.map((mapPath) => (
              <Option key={mapPath} value={mapPath}>
                {mapPath
                  .split('/')
                  .pop()
                  .replace(/(5x5|3x3|_SnowVer).*$/, '')
                  .replace('.png', ' ')
                  .replace('_P', ' ')
                  .replace('_', ' ')
                  .replace(/([A-Z])/g, ' $1')
                  .trim()}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginBottom: 16, marginTop: 10 }}>
        <Col span={8}>
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            Dungeon Mode
          </div>
        </Col>
        <Col span={16}>
          <Radio.Group
            onChange={onDungModeChanged}
            defaultValue={isHr ? 'hr' : 'lr'}
            buttonStyle="solid"
          >
            <Radio.Button value="lr">Low Roller</Radio.Button>
            <Radio.Button value="hr">High Roller</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MapSelection;
