import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import AlchemistImage from '../../img/merchant_avatars/Alchemist.png';
import ArmourerImage from '../../img/merchant_avatars/Armourer.png';
import FortuneTellerImage from '../../img/merchant_avatars/FortuneTeller.png';
import GoblinMerchantImage from '../../img/merchant_avatars/GoblinMerchant.png';
import LeathersmithImage from '../../img/merchant_avatars/Leathersmith.png';
import PumpkinManImage from '../../img/merchant_avatars/PumpkinMan.png';
import SantaImage from '../../img/merchant_avatars/Santa.png';
import SurgeonImage from '../../img/merchant_avatars/Surgeon.png';
import TailorImage from '../../img/merchant_avatars/Tailor.png';
import TavernMasterImage from '../../img/merchant_avatars/TavernMaster.png';
import CollectorImage from '../../img/merchant_avatars/Collector.png';
import TreasurerImage from '../../img/merchant_avatars/Treasurer.png';
import ValentineImage from '../../img/merchant_avatars/Valentine.png';
import WeaponsmithImage from '../../img/merchant_avatars/Weaponsmith.png';
import WoodsmanImage from '../../img/merchant_avatars/Woodsman.png';

const merchantImages = {
  Alchemist: AlchemistImage,
  Armourer: ArmourerImage,
  FortuneTeller: FortuneTellerImage,
  GoblinMerchant: GoblinMerchantImage,
  Leathersmith: LeathersmithImage,
  PumpkinMan: PumpkinManImage,
  Santa: SantaImage,
  Surgeon: SurgeonImage,
  Tailor: TailorImage,
  TavernMaster: TavernMasterImage,
  TheCollector: CollectorImage,
  Treasurer: TreasurerImage,
  Valentine: ValentineImage,
  Weaponsmith: WeaponsmithImage,
  Woodsman: WoodsmanImage,
};

function MerchantAvatar({ name }) {
  const imageSrc = merchantImages[name.split(' ').join('')];
  return <Avatar shape={'square'} src={imageSrc} alt="name" size={'large'} />;
}

MerchantAvatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MerchantAvatar;
