/* eslint-disable react/prop-types */
import { Typography, Row, Col, Flex } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Space, Table, Switch, Input } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { getRarityColor } from '../../shared/text';
import ItemAvatar from './itemAvatar';
const { Title, Paragraph, Text } = Typography;

function TaskTableItem({
  completed,
  taskName,
  name,
  count,
  countCompleted,
  objectiveType,
  handleCountCompletedUpdate,
  map,
  consecEscape,
  noDamage,
  mustEscape,
  singleSession,
  requiredLootState,
  rarity,
  killGrade,
}) {
  const incrementCountCompleted = (e) => {
    e.stopPropagation();
    const incrementedCount = countCompleted + 1;
    if (incrementedCount > count) return;
    handleCountCompletedUpdate(taskName, incrementedCount, objectiveType, name);
  };
  const decrementCountCompleted = (e) => {
    e.stopPropagation();
    const decrementedCount = countCompleted - 1;
    if (decrementedCount < 0) return;
    handleCountCompletedUpdate(taskName, decrementedCount, objectiveType, name);
  };
  const subTaskCompleted = countCompleted === count;
  const getTaskText = () => {
    if (objectiveType === 'survive') {
      return (
        <Text disabled={subTaskCompleted}>
          <span>Escape&nbsp;</span>
          <span
            disabled={subTaskCompleted}
            style={{
              color: getRarityColor(name),
            }}
          >
            {name}
          </span>
          {consecEscape && <Text>&nbsp;consecutively</Text>}
          {noDamage && <Text>&nbsp;(without taking damage)</Text>}
        </Text>
      );
    } else if (objectiveType === 'open') {
      return (
        <Text disabled={subTaskCompleted}>
          <span>Interact with&nbsp;</span>
          <span
            style={{
              color: getRarityColor(name),
            }}
          >
            {name}
          </span>
          {map && (
            <span>
              &nbsp;{'in'}&nbsp;{map}
            </span>
          )}
          {noDamage && <Text>&nbsp;(without taking damage)</Text>}
          {singleSession && <span>&nbsp;in a single session</span>}
          {mustEscape && <span>&nbsp;(must escape)</span>}
        </Text>
      );
    } else if (objectiveType === 'destroy') {
      return (
        <Text disabled={subTaskCompleted}>
          <span>Destroy&nbsp;</span>
          <span
            style={{
              color: getRarityColor(name),
            }}
          >
            {name}
          </span>
          {map && (
            <span>
              &nbsp;{'in'}&nbsp;{map}
            </span>
          )}
          {noDamage && <Text>&nbsp;(without taking damage)</Text>}
          {mustEscape && <span>&nbsp;(must escape)</span>}
          {singleSession && <span>&nbsp;(in a single session)</span>}
        </Text>
      );
    } else if (objectiveType === 'collect') {
      return (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Text disabled={subTaskCompleted}>
            <span>Collect&nbsp;</span>
            <span
              style={
                subTaskCompleted
                  ? {}
                  : {
                      color: getRarityColor(rarity ? rarity : name),
                    }
              }
            >
              {name}
              {rarity && <span>&nbsp;({rarity})</span>}
            </span>
            {requiredLootState && <i>&nbsp;(Looted)</i>}
          </Text>
          <div style={{ marginLeft: 'auto' }}>
            <ItemAvatar name={name.replaceAll(' ', '')} width={30} />
          </div>
        </div>
      );
    } else if (objectiveType === 'kill') {
      return (
        <Text disabled={subTaskCompleted}>
          <span>Kill&nbsp;</span>

          <span>
            {killGrade && <span>({killGrade})&nbsp;</span>}
            {name}
          </span>
          {map && (
            <span>
              &nbsp;{'in'}&nbsp;{map}
            </span>
          )}
          {noDamage && <Text>&nbsp;(without taking damage)</Text>}
          {mustEscape && <span>&nbsp;(must escape)</span>}
          {singleSession && <span>&nbsp;(in a single session)</span>}
        </Text>
      );
    }

    return (
      <Text disabled={subTaskCompleted}>
        <span>
          {/* Capitalize first char */}
          {objectiveType.charAt(0).toUpperCase() + objectiveType.slice(1)}&nbsp;
        </span>
        <span
          style={{
            color: getRarityColor(name),
          }}
        >
          {name}
        </span>
        {map && (
          <span>
            &nbsp;{'in'}&nbsp;{map}
          </span>
        )}
        {noDamage && <Text>&nbsp;(without taking damage)</Text>}
        {mustEscape && <span>&nbsp;(must escape)</span>}
        {singleSession && <span>&nbsp;(in a single session)</span>}
      </Text>
    );
  };

  return (
    <Row style={{ marginBottom: 5 }}>
      <Col
        span={14}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {getTaskText(objectiveType)}
      </Col>
      <Col
        span={3}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          icon={<MinusOutlined />}
          value="small"
          onClick={decrementCountCompleted}
        />
      </Col>
      <Col
        span={4}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {countCompleted} / {count}
      </Col>
      <Col
        span={3}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          icon={<PlusOutlined />}
          value="small"
          onClick={incrementCountCompleted}
        />
      </Col>
    </Row>
  );
}

export default TaskTableItem;
