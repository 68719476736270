/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Typography, Slider, InputNumber, Divider } from 'antd';
import PropTypes from 'prop-types';
import { RedoOutlined } from '@ant-design/icons';
import 'leaflet/dist/leaflet.css';
const { Title, Text } = Typography;

// Reusable component for scale input
const ScaleInput = ({
  title,
  value,
  onChange,
  min = 16,
  max = 100,
  step = 5,
}) => (
  <>
    <Title level={5}>{title}</Title>
    <InputNumber
      style={{ width: '100%', marginBottom: '10px' }}
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
    />
  </>
);

const ScaleSlider = ({
  title,
  value,
  onAfterChange,
  min = 16,
  max = 100,
  step = 1,
}) => {
  const [tempValue, setTempValue] = useState(value);

  const handleSliderChange = (value) => {
    setTempValue(value);
  };

  return (
    <>
      <Text>{title}</Text>
      <Slider
        min={min}
        max={max}
        step={step}
        value={tempValue}
        onChange={handleSliderChange}
        onAfterChange={onAfterChange}
        trackStyle={{ backgroundColor: '#76ABAE' }}
        handleStyle={{ borderColor: '#76ABAE' }}
      />
    </>
  );
};

const MapSettings = ({
  mapIconScale,
  setMapIconScale,
  legendScale,
  setLegendScale,
  moduleIconScale,
  setModuleIconScale,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleVisibilityChange = (flag) => setDropdownVisible(flag);

  const onScaleChange = (setter, key) => (value) => {
    localStorage.setItem(key, value);
    setter(value);
  };

  const resetScales = () => {
    const defaultScale = 32;
    ['legendScale', 'mapIconScale', 'moduleIconScale'].forEach((key) => {
      localStorage.setItem(key, defaultScale);
    });
    setLegendScale(defaultScale);
    setMapIconScale(defaultScale);
    setModuleIconScale(defaultScale);
  };

  return (
    <>
      <Divider>Map Settings</Divider>
      <ScaleSlider
        title="Map Icon Scale"
        value={mapIconScale}
        onAfterChange={(value) => setMapIconScale(value)}
      />
      <ScaleSlider
        title="Module Name Scale"
        value={moduleIconScale}
        onAfterChange={(value) => setModuleIconScale(value)}
      />
      <ScaleSlider
        title="Legend Icon Scale"
        value={legendScale}
        onAfterChange={(value) => setLegendScale(value)}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <Button
          className="button-margin"
          icon={<RedoOutlined style={{ color: '#76ABAE' }} />}
          onClick={resetScales}
        />
      </div>
    </>
  );
};

MapSettings.propTypes = {
  mapIconScale: PropTypes.number.isRequired,
  setMapIconScale: PropTypes.func.isRequired,
  legendScale: PropTypes.number.isRequired,
  setLegendScale: PropTypes.func.isRequired,
  moduleIconScale: PropTypes.number.isRequired,
  setModuleIconScale: PropTypes.func.isRequired,
};

ScaleInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
};
export default MapSettings;
