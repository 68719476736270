import QuestData from '../../data/all_quests.json';

export const processUpdatedQuests = (storedQuests, storedVersion) => {
  const updatedQuests = JSON.parse(JSON.stringify(QuestData['quests']));
  for (const newQuest of updatedQuests) {
    let searchName = newQuest.name;
    if (Array.isArray(storedQuests)) {
      const oldQuest = storedQuests.find((item) => item.name === searchName);

      if (oldQuest) {
        newQuest['completed'] = oldQuest?.['completed'] === true;
        newQuest['favorited'] = oldQuest?.['favorited'] === true;

        for (const key of Object.keys(oldQuest.objectives)) {
          if (newQuest.objectives[key]) {
            for (const oldTask of oldQuest.objectives[key]) {
              const taskToUpdate = newQuest.objectives[key].find(
                (task) => task.name === oldTask.name,
              );

              if (taskToUpdate) {
                taskToUpdate['countCompleted'] =
                  oldTask.countCompleted != undefined
                    ? oldTask.countCompleted
                    : 0;
              }
            }
          }
        }
      }
    }
  }
  return [...updatedQuests];
};
