export const isAvailable = (record, questDataArray) => {
  if (record.confirmed === false) {
    return false;
  }
  if (record.preReq) {
    // Check if the prerequisite quest has been completed
    const preReqCompleted = questDataArray.some(
      (quest) => quest.name === record.preReq && quest.completed,
    );
    return preReqCompleted;
  }
  return true;
};
