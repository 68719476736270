/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  PlusOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Input,
  Dropdown,
  Menu,
  Modal,
  Form,
  Select,
  Image,
  Row,
  Col,
  Typography,
  Divider,
} from 'antd';
import FighterImage from '../img/classIcons/Fighter.png';
import BarbarianImage from '../img/classIcons/Barbarian.png';
import RogueImage from '../img/classIcons/Rogue.png';
import RangerImage from '../img/classIcons/Ranger.png';
import WizardImage from '../img/classIcons/Wizard.png';
import ClericImage from '../img/classIcons/Cleric.png';
import BardImage from '../img/classIcons/Bard.png';
import WarlockImage from '../img/classIcons/Warlock.png';
import DruidImage from '../img/classIcons/Druid.png';

const { confirm } = Modal;
const { Title, Text } = Typography;
const classes = {
  Fighter: FighterImage,
  Barbarian: BarbarianImage,
  Rogue: RogueImage,
  Ranger: RangerImage,
  Wizard: WizardImage,
  Cleric: ClericImage,
  Bard: BardImage,
  Warlock: WarlockImage,
  Druid: DruidImage,
};

// eslint-disable-next-line react/prop-types
const CharacterSelect = ({
  characters,
  setAndStoreCharacters,
  selectedCharacter,
  setAndStoreSelectedCharacter,
  menuCollapsed,
}) => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null); // for edits

  const [form] = Form.useForm();

  const showDeleteConfirm = (item) => {
    if (characters.length === 1) {
      showCannotDeleteWarning();
    } else {
      confirm({
        title: 'Are you sure you want to delete this character?',
        icon: <ExclamationCircleOutlined />,
        content: `${item.name} will be permanently deleted.`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          removeItem(item);
        },
      });
    }
  };

  const showCannotDeleteWarning = () => {
    Modal.warning({
      title: 'Cannot delete your last character',
      content: 'You must have at least one character.',
    });
  };

  const removeItem = (item) => {
    const updatedItems = characters.filter((i) => i !== item);
    setAndStoreCharacters(updatedItems);
    if (item.name === selectedCharacter) {
      setAndStoreSelectedCharacter(
        updatedItems.length > 0 ? updatedItems[0].name : '',
      );
    }
  };

  const handleMenuClick = (item) => {
    setAndStoreSelectedCharacter(item.name);
  };

  const handleAddCharacter = (values) => {
    const nameExists = characters.some((item) => item.name === values.name);
    if (nameExists) {
      form.setFields([
        {
          name: 'name',
          errors: ['A character with this name already exists.'],
        },
      ]);
      return;
    }
    const newItem = {
      name: values.name,
      class: values.class,
      icon: classes[values.class],
    };

    setAndStoreCharacters([...characters, newItem]);
    setAndStoreSelectedCharacter(newItem.name);
    setIsAddModalVisible(false);
    form.resetFields();
  };

  const handleEditCharacter = (values) => {
    const nameExists = characters.some(
      (item) => item.name === values.name && item.name !== currentItem.name,
    );

    if (nameExists) {
      form.setFields([
        {
          name: 'name',
          errors: ['A character with this name already exists.'],
        },
      ]);
      return;
    }
    const updatedItems = characters.map((item) =>
      item.name === currentItem.name
        ? {
            ...item,
            name: values.name,
            class: values.class,
            icon: classes[values.class],
          }
        : item,
    );
    setAndStoreCharacters(updatedItems);
    setIsEditModalVisible(false);

    setAndStoreSelectedCharacter(values.name);
    setCurrentItem(null);
    form.resetFields();
  };

  const showEditModal = (item) => {
    setCurrentItem(item);
    form.setFieldsValue({ name: item.name, class: item.class });
    setIsEditModalVisible(true);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="title"
        style={{
          cursor: 'default',
          fontWeight: 'bold',
          backgroundColor: 'inherit',
          pointerEvents: 'none',
        }}
      >
        <div style={{ textAlign: 'center' }}>Choose your Character</div>
      </Menu.Item>
      {characters.map((item) => (
        <Menu.Item
          style={{ width: 250 }}
          key={item.name}
          onClick={() => handleMenuClick(item)}
        >
          <Row align="middle">
            <Col
              span={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                width={30}
                preview={false}
                src={classes[item.class]}
                alt={item.class}
              />
            </Col>
            <Col
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {item.name}
            </Col>
            <Col
              span={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                shape="square"
                size="small"
                icon={<EditOutlined />}
                onClick={() => showEditModal(item)}
              />
            </Col>
            <Col
              span={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                shape="square"
                size="small"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  showDeleteConfirm(item);
                }}
              />
            </Col>
          </Row>
        </Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item key="add" onClick={() => setIsAddModalVisible(true)}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PlusOutlined />
        </div>
      </Menu.Item>
    </Menu>
  );
  const character = characters.find(
    (character) => character.name === selectedCharacter,
  );
  if (!character) {
    setAndStoreSelectedCharacter(characters[0].name);
  }
  const selectedClass = character.class;

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
        <div
          //   shape="circle"
          style={{
            width: menuCollapsed ? 80 : 200,
            height: 80,
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            cursor: 'pointer',
          }}
        >
          <Image
            width={60}
            preview={false}
            src={classes[selectedClass]}
            alt={selectedClass}
            style={{ marginBottom: 4 }} // Adjust spacing between image and text
          />
          {!menuCollapsed && (
            <Text bold style={{ fontSize: 18, color: '#76ABAE' }}>
              {selectedCharacter}
            </Text>
          )}
        </div>
      </Dropdown>
      <Modal
        title="Add New Character"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleAddCharacter}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: 'Please input the character name!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="class"
            label="Class"
            rules={[
              { required: true, message: 'Please select the character class!' },
            ]}
          >
            <Select>
              {Object.keys(classes).map((className) => (
                <Select.Option key={className} value={className}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Image
                      preview={false}
                      width={30}
                      src={classes[className]}
                      alt={className}
                      style={{ marginRight: 8 }}
                    />
                    {className}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Character
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Character"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleEditCharacter}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: 'Please input the character name!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="class"
            label="Class"
            rules={[
              { required: true, message: 'Please select the character class!' },
            ]}
          >
            <Select>
              {Object.keys(classes).map((className) => (
                <Select.Option key={className} value={className}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Image
                      preview={false}
                      width={30}
                      src={classes[className]}
                      alt={className}
                      style={{ marginRight: 8 }}
                    />
                    {className}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CharacterSelect;
