import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ConfigProvider, theme, Layout, Typography, Row } from 'antd';

import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import PDRCalculator from './components/calculators/pdrCalc';
import BuffDurationCalculator from './components/calculators/buffdurationcalc';
import UnderConstruction from './components/underConstruction';
import QuestTracker from './components/Quests/questtracker';
import DadMenu from './components/DadMenu';
import MapSelector from './components/map/mapMain';
import KitBuilder from './components/Kits/kitBuilder';
import HomePage from './components/homePage';
import ReactGA from 'react-ga4';
import { ExistingIconsProvider } from './components/map/iconContext';

import {
  calcEhpPath,
  calcBuffDurationPath,
  questTrackerPath,
  mapSelectorPath,
  questItemsPath,
  kitBuilderPath,
} from './data/paths';
import QuestItems from './components/Quests/questitems';
const { Title } = Typography;
const { Content, Footer } = Layout;

function App() {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [characters, setCharacters] = useState(null);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const setAndStoreDadMenuCollapsed = (value) => {
    localStorage.setItem('isDadMenuCollapsed', JSON.stringify(value));
    setMenuCollapsed(value);
  };

  const setAndStoreCharacters = (value) => {
    localStorage.setItem('characters', JSON.stringify(value));
    setCharacters(value);
  };

  const setAndStoreSelectedCharacter = (value) => {
    localStorage.setItem('selectedCharacter', JSON.stringify(value));
    setSelectedCharacter(value);
  };

  useEffect(() => {
    ReactGA.initialize('G-PJ8G13VXEE');

    const storedIsDadMenuCollapsedState =
      localStorage.getItem('isDadMenuCollapsed');
    if (storedIsDadMenuCollapsedState != undefined) {
      setMenuCollapsed(JSON.parse(storedIsDadMenuCollapsedState));
    }
    const storedCharacters = localStorage.getItem('characters');
    if (storedCharacters != undefined) {
      setCharacters(JSON.parse(storedCharacters));

      const storedSelectedCharacter = localStorage.getItem('selectedCharacter');

      if (storedSelectedCharacter != undefined) {
        setSelectedCharacter(JSON.parse(storedSelectedCharacter));
      }
    } else {
      setCharacters([{ name: 'Timmy', class: 'Fighter' }]);
      setSelectedCharacter('Timmy');
    }
  }, []);

  if (characters === null) {
    return <>Loading...</>;
  }
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#76ABAE',
          colorInfo: '#ffd400',
          colorBgBase: '#222831',
          colorTextBase: '#EEEEEE',
        },
        components: {
          Table: {
            cellPaddingBlock: 8,
          },
        },
      }}
    >
      <BrowserRouter className="App">
        <Layout style={{ minHeight: '100vh' }}>
          <DadMenu
            characters={characters}
            setAndStoreCharacters={setAndStoreCharacters}
            selectedCharacter={selectedCharacter}
            setAndStoreSelectedCharacter={setAndStoreSelectedCharacter}
            menuCollapsed={menuCollapsed}
            setMenuCollapsed={setAndStoreDadMenuCollapsed}
          />
          <Layout>
            <Content style={{ margin: '0 16px' }}>
              <div
                style={{
                  padding: 24,
                  minHeight: 360,
                }}
              >
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path={calcEhpPath} element={<PDRCalculator />} />
                  {/* <Route
                    path={calcBuffDurationPath}
                    element={<BuffDurationCalculator />}
                  /> */}
                  <Route
                    path={questTrackerPath}
                    element={
                      <QuestTracker
                        characters={characters}
                        setAndStoreCharacters={setAndStoreCharacters}
                        selectedCharacter={selectedCharacter}
                      />
                    }
                  />

                  <Route
                    path={mapSelectorPath}
                    element={
                      <ExistingIconsProvider>
                        <MapSelector
                          menuCollapsed={menuCollapsed}
                          characters={characters}
                          setAndStoreCharacters={setAndStoreCharacters}
                          selectedCharacter={selectedCharacter}
                        />
                      </ExistingIconsProvider>
                    }
                  />
                  <Route
                    path={questItemsPath}
                    element={
                      <QuestItems
                        characters={characters}
                        setAndStoreCharacters={setAndStoreCharacters}
                        selectedCharacter={selectedCharacter}
                      />
                    }
                  />
                  <Route path={kitBuilderPath} element={<KitBuilder />} />
                </Routes>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              <Row className="center-contents" style={{ paddingBottom: 10 }}>
                Game content and materials are trademarks and copyrights of
                Ironmace and its licensors. All rights reserved.
              </Row>
              <Row className="center-contents">
                ©{new Date().getFullYear()} Created by Awkward & Dbleh
              </Row>
            </Footer>
          </Layout>
        </Layout>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
