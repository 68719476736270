import React, { useState, useEffect } from 'react';
import { Select, Form } from 'antd';
import characterData from '../../data/characterStats.json';
import PropTypes from 'prop-types';
import FileSelect from './fileSelect.js';
import bgImage from '../../img/itemIcons/image.png';
import {
  calculatePhysicalPowerBonus,
  calculateHealthRecoveryBonus,
  calculateitemEquipSpeed,
  calculateMoveSpeed,
  calculateManualDexterity,
  calculateMagicalPowerBonus,
  calculateMagicResistance,
  calculateMagicalDamageReduction,
  calculateDebuffDuration,
  calculateBuffDuration,
  calculateMagicalInteractionSpeed,
  calculateSpellCastingSpeed,
  calculatePhysicalDamageReduction,
  calculateRegularInteractionSpeed,
  calculateMemoryCapacity,
  calculateMemoryRecovery,
  calculateCoolDownReduction,
  calculateResourcefulness,
  calculateMaxHealth,
  calculateActionSpeed,
  calculateMagicalPower,
  calculatePhysicalPower,
} from './statCalculations.js';

const { Option } = Select;

const folders = [
  'Back',
  'Chest',
  'Foot',
  'Hands',
  'Head',
  'Legs',
  'Necklace',
  'Primary',
  'Primary2',
  'Ring1',
  'Ring2',
  'Secondary',
  'Secondary2',
  'Utility',
];

const propertyOrder = [
  'Strength',
  'Vigor',
  'Agility',
  'Dexterity',
  'Will',
  'Knowledge',
  'Resourcefulness',
  'Health',
  'Memory Capacity',
  'Id_ItemPropertyType_Effect_Luck',
  'Health Recovery',
  'Spell Recovery Bonus',
  'Move Speed',
  'Action Speed',
  'Manual Dexterity',
  'Spell Casting Speed',
  'Item Equip Speed',
  'Regular Interaction Speed',
  'Magical Interaction Speed',
  'Persuasiveness',
  'Buff Duration',
  'Debuff Duration',
  'Cooldown Reduction Bonus',
  'Armor Penetration',
  'Magic Penetration',
  'Id_ItemPropertyType_Effect_HeadshotReductionMod',
  'Id_ItemPropertyType_Effect_ProjectileReductionMod',
  'Armor Rating',
  'Id_ItemPropertyType_Effect_ArmorRating',
  'Physical Damage Reduction',
  'Physical Power',
  'Physical Power Bonus',
  'Magical Power',
  'Magical Power Bonus',
  'Magic Resistance',
  'Magical Damage Reduction',
  'Memory Recovery',
  'Cooldown Reduction',
];

const propertyAdditions = {
  Id_ItemPropertyType_Effect_MaxHealthAdd: 'Health',
  Id_ItemPropertyType_Effect_Strength: 'Strength',
  Id_ItemPropertyType_Effect_Dexterity: 'Dexterity',
  Id_ItemPropertyType_Effect_Vigor: 'Vigor',
  Id_ItemPropertyType_Effect_Agility: 'Agility',
  Id_ItemPropertyType_Effect_Will: 'Will',
  Id_ItemPropertyType_Effect_Knowledge: 'Knowledge',
  Id_ItemPropertyType_Effect_Resourcefulness: 'Resourcefulness',
  Id_ItemPropertyType_Effect_ArmorRatingAdd:
    'Id_ItemPropertyType_Effect_ArmorRating',
  Id_ItemPropertyType_Effect_MoveSpeedAdd: 'Move Speed',
  Id_ItemPropertyType_Effect_PhysicalDamageReduction:
    'Physical Damage Reduction',
  Id_ItemPropertyType_Effect_PhysicalPower: 'Physical Power',
  Id_ItemPropertyType_Effect_MagicalDamageReduction: 'Magical Damage Reduction',
};
const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};
const Back = importAll(
  require.context('../../data/slots/Back', false, /\.(json|jpe?g|svg)$/),
);
const Chest = importAll(
  require.context('../../data/slots/Chest', false, /\.(json|jpe?g|svg)$/),
);
const Foot = importAll(
  require.context('../../data/slots/Foot', false, /\.(json|jpe?g|svg)$/),
);
const Hands = importAll(
  require.context('../../data/slots/Hands', false, /\.(json|jpe?g|svg)$/),
);
const Head = importAll(
  require.context('../../data/slots/Head', false, /\.(json|jpe?g|svg)$/),
);
const Legs = importAll(
  require.context('../../data/slots/Legs', false, /\.(json|jpe?g|svg)$/),
);
const Necklace = importAll(
  require.context('../../data/slots/Necklace', false, /\.(json|jpe?g|svg)$/),
);
const Primary = importAll(
  require.context('../../data/slots/Primary', false, /\.(json|jpe?g|svg)$/),
);

const Primary2 = importAll(
  require.context('../../data/slots/Primary2', false, /\.(json|jpe?g|svg)$/),
);
const Ring1 = importAll(
  require.context('../../data/slots/Ring1', false, /\.(json|jpe?g|svg)$/),
);
const Ring2 = importAll(
  require.context('../../data/slots/Ring2', false, /\.(json|jpe?g|svg)$/),
);
const Secondary = importAll(
  require.context('../../data/slots/Secondary', false, /\.(json|jpe?g|svg)$/),
);

const Secondary2 = importAll(
  require.context('../../data/slots/Secondary2', false, /\.(json|jpe?g|svg)$/),
);
const Utility = importAll(
  require.context('../../data/slots/Utility', false, /\.(json|jpe?g|svg)$/),
);

// Calculate the scale and yScale based on the screen width and height

function KitBuilder() {
  const [selectedFiles, setSelectedFiles] = useState({});

  const [allPrimaryProperties, setAllPrimaryProperties] = useState({});

  const [completeProperties, setCompleteProperties] = useState({});

  const [searchTerm, setSearchTerm] = useState('');

  const [characterProperties, setCharacterProperties] = useState({
    Strength: 15,
    Vigor: 15,
    Agility: 15,
    Dexterity: 15,
    Will: 15,
    Knowledge: 15,
    Resourcefulness: 15,
    Health: 100,
  });

  const [selectedCharacter, setSelectedCharacter] = useState(
    characterData.Classes.find((character) => character.Name === 'Fighter'),
  );

  const handleCharacterChange = (value) => {
    const selected = characterData.Classes.find(
      (character) => character.Name === value,
    );
    setSelectedCharacter(selected);

    const { name, ...rest } = selected;

    setCharacterProperties(rest);
  };

  useEffect(() => {
    const loadMapData = async () => {
      const allFiles = {};
      for (const folder of folders) {
        switch (folder) {
          case 'Back':
            allFiles[folder] = Back;
            break;
          case 'Chest':
            allFiles[folder] = Chest;
            break;
          case 'Foot':
            allFiles[folder] = Foot;
            break;
          case 'Hands':
            allFiles[folder] = Hands;
            break;
          case 'Head':
            allFiles[folder] = Head;
            break;
          case 'Legs':
            allFiles[folder] = Legs;
            break;
          case 'Necklace':
            allFiles[folder] = Necklace;
            break;
          case 'Primary':
            allFiles[folder] = Primary;
            break;
          case 'Primary2':
            allFiles[folder] = Primary2;
            break;
          case 'Ring1':
            allFiles[folder] = Ring1;
            break;
          case 'Ring2':
            allFiles[folder] = Ring2;
            break;
          case 'Secondary':
            allFiles[folder] = Secondary;
            break;
          case 'Secondary2':
            allFiles[folder] = Secondary2;
            break;
          case 'Utility':
            allFiles[folder] = Utility;
            break;
          default:
            console.log('no folder found');
        }
      }

      setSelectedFiles(allFiles);
    };

    loadMapData();
  }, []);

  useEffect(() => {
    let combinedProperties = {
      ...characterProperties,
      ...Object.values(allPrimaryProperties).reduce((acc, curr) => {
        Object.entries(curr).forEach(([key, value]) => {
          if (acc[key]) {
            acc[key] += value;
          } else {
            acc[key] = value;
          }
        });
        return acc;
      }, {}),
    };
    Object.entries(propertyAdditions).forEach(([addKey, targetKey]) => {
      if (combinedProperties[addKey]) {
        if (!combinedProperties[targetKey]) {
          combinedProperties[targetKey] = 0;
        }

        combinedProperties[targetKey] += combinedProperties[addKey];
        delete combinedProperties[addKey];
      }
    });

    if (combinedProperties['Strength']) {
      combinedProperties['Physical Power'] = calculatePhysicalPower(
        combinedProperties['Strength'],
      );
    }

    if (combinedProperties['Physical Power']) {
      combinedProperties['Physical Power Bonus'] = calculatePhysicalPowerBonus(
        combinedProperties['Physical Power'],
      );
    }

    if (combinedProperties['Vigor']) {
      combinedProperties['Health Recovery'] = calculateHealthRecoveryBonus(
        combinedProperties['Vigor'],
      );
    }

    if (combinedProperties['Agility']) {
      combinedProperties['Move Speed'] = calculateMoveSpeed(
        combinedProperties['Agility'],
      );
    }

    if (combinedProperties['Dexterity']) {
      combinedProperties['Manual Dexterity'] = calculateManualDexterity(
        combinedProperties['Dexterity'],
      );
    }

    if (combinedProperties['Dexterity']) {
      combinedProperties['Item Equip Speed'] = calculateitemEquipSpeed(
        combinedProperties['Dexterity'],
      );
    }

    if (combinedProperties['Will']) {
      combinedProperties['Magical Power'] = calculateMagicalPower(
        combinedProperties['Will'],
      );
    }

    if (combinedProperties['Magical Power']) {
      combinedProperties['Magical Power Bonus'] = calculateMagicalPowerBonus(
        combinedProperties['Magical Power'],
      );
    }
    if (combinedProperties['Will']) {
      combinedProperties['Magic Resistance'] = calculateMagicResistance(
        combinedProperties['Will'],
      );
    }

    if (combinedProperties['Magic Resistance']) {
      combinedProperties['Magical Damage Reduction'] =
        calculateMagicalDamageReduction(
          combinedProperties['Magic Resistance'],
        ) + '%';
    }

    if (combinedProperties['Will']) {
      combinedProperties['Buff Duration'] = calculateBuffDuration(
        combinedProperties['Will'],
      );
    }

    if (combinedProperties['Will']) {
      combinedProperties['Magical Interaction Speed'] =
        calculateMagicalInteractionSpeed(combinedProperties['Will']);
    }

    if (combinedProperties['Will']) {
      combinedProperties['Debuff Duration'] = calculateDebuffDuration(
        combinedProperties['Will'],
      );
    }

    if (combinedProperties['Knowledge']) {
      combinedProperties['Spell Casting Speed'] = calculateSpellCastingSpeed(
        combinedProperties['Knowledge'],
      );
    }

    if (combinedProperties['Knowledge']) {
      combinedProperties['Memory Capacity'] = calculateMemoryCapacity(
        combinedProperties['Knowledge'],
      );
    }

    if (combinedProperties['Knowledge']) {
      combinedProperties['Memory Recovery'] = calculateMemoryRecovery(
        combinedProperties['Knowledge'],
      );
    }

    if (combinedProperties['Resourcefulness']) {
      combinedProperties['Cooldown Reduction'] = calculateCoolDownReduction(
        combinedProperties['Resourcefulness'],
      );
    }

    if (combinedProperties['Resourcefulness']) {
      combinedProperties['Persuasiveness'] = calculateResourcefulness(
        combinedProperties['Resourcefulness'],
      );
    }

    if (combinedProperties['Strength']) {
      combinedProperties['Max Health'] = calculateMaxHealth(
        combinedProperties['Strength'] * 0.25 +
          combinedProperties['Vigor'] * 0.75,
      );
    }

    if (combinedProperties['Agility']) {
      combinedProperties['Action Speed'] = calculateActionSpeed(
        combinedProperties['Agility'] * 0.25 +
          combinedProperties['Dexterity'] * 0.75,
      );
    }

    if (combinedProperties['Agility']) {
      combinedProperties['Regular Interaction Speed'] =
        calculateRegularInteractionSpeed(
          combinedProperties['Agility'] * 0.4 +
            combinedProperties['Resourcefulness'] * 0.6,
        );
    }

    if (combinedProperties['Strength']) {
      combinedProperties['Physical Damage Reduction'] =
        calculatePhysicalDamageReduction(
          combinedProperties['Id_ItemPropertyType_Effect_ArmorRating'],
        );
    }
    if (
      combinedProperties['Id_ItemPropertyType_Effect_MoveSpeed'] !== undefined
    ) {
      combinedProperties['Move Speed'] =
        combinedProperties['Id_ItemPropertyType_Effect_MoveSpeed'] +
        combinedProperties['Move Speed'];
      delete combinedProperties['Id_ItemPropertyType_Effect_MoveSpeed'];
    }

    if (
      combinedProperties['Regular Interaction Speed'] &&
      combinedProperties['Id_ItemPropertyType_Effect_RegularInteractionSpeed']
    ) {
      combinedProperties['Regular Interaction Speed'] +=
        combinedProperties[
          'Id_ItemPropertyType_Effect_RegularInteractionSpeed'
        ];
      combinedProperties['Regular Interaction Speed'] *= 100;
      combinedProperties['Regular Interaction Speed'] =
        combinedProperties['Regular Interaction Speed'].toFixed(2) + '%';
      delete combinedProperties[
        'Id_ItemPropertyType_Effect_RegularInteractionSpeed'
      ];
    }
    if (combinedProperties['Max Health']) {
      delete combinedProperties['Max Health'];
    }

    setCompleteProperties(combinedProperties);
  }, [allPrimaryProperties, characterProperties]);

  return (
    <div>
      <div>
        <Form.Item label="Character">
          <Select
            style={{ width: '90%' }}
            defaultValue={selectedCharacter.Name}
            onChange={handleCharacterChange}
          >
            {characterData.Classes.map((character) => (
              <Option key={character.Name} value={character.Name}>
                {character.Name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <br></br>
        <div className="my-scrollbar"
          style={{
            position: 'fixed',
            top: '70px',
            width: '20vw',
            zIndex: '10',
            overflowY: 'auto',
            maxHeight: '90vh',
          }}
        >
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {completeProperties && (
  <>
  {console.log(completeProperties)}
    <p>Gear Score: {completeProperties.gearScore}</p>
    {Object.entries(completeProperties)
  .filter(([key]) => key && key !== 'gearScore' && key !== 'Name')
  .filter(([key]) => key.toLowerCase().includes(searchTerm.toLowerCase()))
  .sort(([keyA], [keyB]) => propertyOrder.indexOf(keyA) - propertyOrder.indexOf(keyB))
  .map(([key, value]) => key !== undefined && (
    <p key={key}>
      {key.includes('ItemPropertyType')
        ? key.split('_')[3].replace(/(?<=.)([A-Z])/g, ' $1')
        : key}
      : {value !== undefined ? value.toString() : 'N/A'}
    </p>
  ))}
  </>
)}
        </div>
      </div>

      <div
        style={{
          marginLeft: '14vw',
          marginTop: '0px',
          width: '65vw',
          height: '90vh',
          position: 'absolute',
          overflowX: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(30, 1fr)',
            gridTemplateRows: 'repeat(30, 1fr)',
            gridGap: '1px',
            height: '100%',
            width: '100%',
            position: 'absolute',
            marginLeft: '-4vw',
            transform: 'scale(.9)',
            marginTop: '-8vh',
          }}
        >
          <img
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%) scale(20)',
              width: 'calc(2vw)',
              height: '4.5%',
            }}
            src={bgImage}
            alt="Character"
          />
          <FileSelect
            folder="Head"
            style={{ gridColumn: '15 / span 2', gridRow: '6 / span 4' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Chest"
            style={{ gridColumn: '14 / span 4', gridRow: '10 / span 9' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Legs"
            style={{ gridColumn: '14 / span 4', gridRow: '19 / span 8' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Foot"
            style={{ gridColumn: '18 / span 3', gridRow: '22 / span 5' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Necklace"
            style={{ gridColumn: '17 / span 3', gridRow: '7 / span 3' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Primary"
            style={{ gridColumn: '7 / span 3', gridRow: '5 / span 9' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Secondary"
            style={{ gridColumn: '10 / span 2', gridRow: '5 / span 9' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Primary2"
            style={{ gridColumn: '20 / span 3', gridRow: '5 / span 9' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Secondary2"
            style={{ gridColumn: '23 / span 2', gridRow: '5 / span 9' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Hands"
            style={{ gridColumn: '11 / span 3', gridRow: '14 / span 5' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Back"
            style={{ gridColumn: '18 / span 3', gridRow: '14 / span 5' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Ring1"
            style={{ gridColumn: '12 / span 2', gridRow: '19 / span 3' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Ring2"
            style={{ gridColumn: '18 / span 2', gridRow: '19 / span 3' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
          <FileSelect
            folder="Utility"
            style={{ gridColumn: '7 / span 3', gridRow: '19 / span 3' }}
            setAllPrimaryProperties={setAllPrimaryProperties}
            allPrimaryProperties={allPrimaryProperties}
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
          />
        </div>
      </div>
    </div>
  );
}

KitBuilder.propTypes = {
  character: PropTypes.object,
  setCharacter: PropTypes.func,
  folder: PropTypes.string,
  handleSelect: PropTypes.func,
  selectedFiles: PropTypes.object,
  style: PropTypes.object,
};

export default KitBuilder;
