/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Divider, Button, Tooltip, Row, Col } from 'antd';
import { processUpdatedQuests } from '../Quests/processUpdatedQuests';
import { currentQuestDataVersion } from '../../data/versionInfo';
import QuestData from '../../data/all_quests.json';
import { isAvailable } from '../Quests/questShared';
import TaskTableItem from '../Quests/TaskTableItem';
import { CheckOutlined } from '@ant-design/icons';

const MapActiveQuests = ({
  mapName,
  idk,
  selectedCharacter,
  characters,
  setAndStoreCharacters,
}) => {
  const [questDataArray, setQuestDataArray] = useState([]);
  const [filteredQuestData, setFilteredQuestData] = useState([]);

  useEffect(() => {
    const selectedCharObj = characters.find(
      (character) => character.name === selectedCharacter,
    );

    if (selectedCharObj?.questData) {
      const storedQuests = selectedCharObj.questData;

      const storedVersion = selectedCharObj.dataVersion;
      if (storedVersion < 5) {
        // wipee reset all progress
        updateQuestData(QuestData['quests']);
        return;
      }
      if (storedVersion == currentQuestDataVersion) {
        updateQuestData(storedQuests);
      }
      const updatedQuests = processUpdatedQuests(storedQuests, storedVersion);
      updateQuestData(updatedQuests);
    } else {
      updateQuestData(QuestData['quests']);
    }
  }, [selectedCharacter]);

  useEffect(() => {
    const availableData = questDataArray.filter((record) => {
      const isAvailableRecord = isAvailable(record, questDataArray);
      const isNotCompleted = !record.completed;
      const includesAny = record.mapTags && record.mapTags.includes('any');
      const lowerCaseMapName = mapName.toLowerCase().replace(' ', '');
      const includesMapName =
        record.mapTags &&
        mapName &&
        record.mapTags.some((tag) => {
          const lowerCaseTag = tag.toLowerCase().replace(' ', '');

          const includes = lowerCaseTag.includes(lowerCaseMapName);
          return includes;
        });
      return (
        isAvailableRecord && isNotCompleted && (includesAny || includesMapName)
      );
    });

    setFilteredQuestData(availableData);
  }, [questDataArray, mapName]);

  const updateQuestData = (newQuestData) => {
    const anotherCopy = JSON.parse(JSON.stringify(newQuestData));
    // Update the specific character
    const updatedCharacters = characters.map((character) =>
      character.name === selectedCharacter
        ? {
            ...character,
            questData: anotherCopy,
            dataVersion: currentQuestDataVersion,
          }
        : character,
    );

    setAndStoreCharacters(updatedCharacters);
    setQuestDataArray(JSON.parse(JSON.stringify(newQuestData)));
  };

  const handleCountCompletedUpdate = (
    taskName,
    countCompleted,
    objectiveType,
    name,
  ) => {
    const questDataArrayCopy = [...questDataArray];
    const idxQuest = questDataArrayCopy.findIndex(
      (obj) => obj.name === taskName,
    );
    const questTypeObj = questDataArrayCopy[idxQuest].objectives[objectiveType];
    const idxSubTask = questTypeObj.findIndex((obj) => obj.name === name);
    questTypeObj[idxSubTask].countCompleted = countCompleted;
    updateQuestData(questDataArrayCopy);
  };

  const onCompletedChanged = (questName) => {
    const questDataArrayCopy = [...questDataArray];
    const idxQuest = questDataArrayCopy.findIndex(
      (obj) => obj.name === questName,
    );

    questDataArrayCopy[idxQuest].completed = true;

    updateQuestData(questDataArrayCopy);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filteredQuestData.map((record) => (
          <React.Fragment>
            <div
              style={{
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              <Row justify="space-between" align="middle">
                <Col flex="auto">
                  <Divider
                    dashed
                    orientation="left"
                    style={{
                      margin: 0,
                      fontSize: 12,
                      color: '#76ABAE',
                      width: '100%',
                    }}
                  >
                    {record.name}
                  </Divider>
                </Col>
                <Col>
                  <Tooltip
                    style={{ float: 'right' }}
                    title={
                      <span>
                        Mark <b style={{ color: '#76ABAE' }}>{record.name}</b>{' '}
                        Complete
                      </span>
                    }
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<CheckOutlined />}
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        onCompletedChanged(record.name);
                      }}
                    ></Button>
                  </Tooltip>
                </Col>
              </Row>
            </div>
            {Object.keys(record.objectives).map((objectiveKey, index) => (
              <React.Fragment key={index}>
                {record.objectives[objectiveKey].map((task, taskIndex) => (
                  <TaskTableItem
                    completed={record.completed}
                    taskName={record.name}
                    key={taskIndex}
                    name={task.name}
                    count={task.count}
                    countCompleted={task.countCompleted || 0}
                    objectiveType={objectiveKey}
                    map={task.map}
                    handleCountCompletedUpdate={handleCountCompletedUpdate}
                    consecEscape={task.consecutiveEscape}
                    noDamage={task.noDamage}
                    mustEscape={task.mustEscape}
                    singleSession={task.singleSession}
                    requiredLootState={task.requiredLootedState}
                    rarity={task.rarity}
                    killGrade={task.killGrade}
                  />
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

MapActiveQuests.propTypes = {
  mapName: PropTypes.string.isRequired,
  idk: PropTypes.string.isRequired,
};

export default MapActiveQuests;
