import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import idToIcon from '../../data/idToIcon.json';
const ExistingIconsContext = createContext();

export const ExistingIconsProvider = ({ children }) => {
  const [existingIcons, setExistingIcons] = useState({});

  const keys = Object.keys(idToIcon);
  const initialState = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (!Object.prototype.hasOwnProperty.call(initialState, idToIcon[key])) {
      initialState[idToIcon[key]] = false;
    }
  }
  const [selectedIcons, setSelectedIcons] = useState(
    JSON.stringify(localStorage.getItem('selectedIcons'))
      ? JSON.stringify(localStorage.getItem('selectedIcons'))
      : initialState,
  );

  return (
    <ExistingIconsContext.Provider
      value={{
        existingIcons,
        setExistingIcons,
        selectedIcons,
        setSelectedIcons,
      }}
    >
      {children}
    </ExistingIconsContext.Provider>
  );
};

ExistingIconsProvider.propTypes = {
  children: PropTypes.node,
  initialExistingIcons: PropTypes.object,
  updateExistingIcons: PropTypes.func,
};

export const useExistingIcons = () => React.useContext(ExistingIconsContext);