import rarityColor from '../data/rarityColor.json';
// File for text utility functions

const reversedRarityColors = Object.entries(rarityColor).reverse();
console.log(reversedRarityColors);
export const getRarityColor = (str) => {
  let rarity = rarityColor[str];
  if (rarity) {
    str = rarity;
  }

  str = str.replace('Stash Tab', 'unique');
  str = str.replace('Coin Chest', 'unique');
  str = str.replace('Coin Bag', 'unique');
  str = str.replace('Golden Key', 'unique');
  str = str.replace('Skull Key', 'unique');
  const lowerStr = str.toLowerCase();

  const matchingEntry = reversedRarityColors.find(([key]) =>
    lowerStr.includes(key.toLowerCase()),
  );

  if (matchingEntry) {
    const [matchingKey] = matchingEntry;
    return rarityColor[matchingKey];
  } else {
    return null; // or any default color
  }
};
