import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

// Import all images from the specified folder
const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};

const mapExploreImages = importAll(
  require.context('../../img/mapExplore', false, /\.(png|jpe?g|svg)$/),
);

const trueNameMap = {};

// eslint-disable-next-line react/prop-types
function MapExploreAvatar({ name, width, map }) {
  let rarity = null;

  let trueName = trueNameMap[name];
  if (trueName) {
    name = trueName;
  }
  let imageSrc = mapExploreImages[name + '.png'];

  if (imageSrc === undefined) {
    // eslint-disable-next-line react/prop-types
    imageSrc = mapExploreImages[name + '_' + map.toLowerCase() + '.png'];
  }

  if (imageSrc === undefined) {
    // eslint-disable-next-line react/prop-types
    console.log(name + '_' + map.toLowerCase() + '.png not found');
    return <div style={{ width: width, height: width }} />;
  }
  return (
    <img
      shape={'square'}
      src={imageSrc}
      alt={name}
      style={{ objectFit: 'contain', width: width, height: width }}
    />
  );
}

MapExploreAvatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MapExploreAvatar;
