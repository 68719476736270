import { Typography, Row, Form, Col, InputNumber, Card, Divider } from 'antd';
import React, { useState, useEffect } from 'react';
import CalculatorCard from './calculatorCard';
import ReactGA from 'react-ga4';
// import { useAuth } from "../auth";
const { Title, Paragraph } = Typography;

function EHPCalculator() {
  const [health, setHealth] = useState(100);
  const [pdr, setPdr] = useState(0);
  const [mdr, setMdr] = useState(0);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/ehp',
      title: 'Effective Hp Calc',
    });
  }, []);

  const calcEffectiveHealth = (damageReduction) =>
    ((100 / (100 - damageReduction)) * health).toFixed(0);

  const onPdrChange = (value) => {
    setPdr(value);
  };
  const onMdrChange = (value) => {
    setMdr(value);
  };

  const onHealthChange = (value) => {
    setHealth(value);
  };

  const ehpInputComponents = [
    <Form.Item label="Health">
      <InputNumber
        min={70}
        max={250}
        defaultValue={100}
        onChange={onHealthChange}
      />
    </Form.Item>,
    <Form.Item label="Physical Damage Reduction">
      <InputNumber
        min={0}
        max={85}
        defaultValue={0}
        onChange={onPdrChange}
        formatter={(value) => `${value}%`}
        parser={(value) => value.replace('%', '')}
      />
    </Form.Item>,
    <Form.Item label="Magical Damage Reduction">
      <InputNumber
        min={0}
        max={85}
        defaultValue={0}
        onChange={onMdrChange}
        formatter={(value) => `${value}%`}
        parser={(value) => value.replace('%', '')}
      />
    </Form.Item>,
  ];
  const ehpOutputObjects = [
    { outputLabel: 'Physical Effective HP', value: calcEffectiveHealth(pdr) },
    { outputLabel: 'Magical Effective HP', value: calcEffectiveHealth(mdr) },
  ];

  const ehpDescriptionComponents = [
    <Paragraph>
      Effective HP (EHP) refers to the calculated or adjusted amount of health a
      character has, taking into account any damage mitigation or reduction
      factors. It&apos;s a way to quantify the survivability of a character
      beyond their raw health pool.
    </Paragraph>,
    <Paragraph>
      <b>Note</b>: True damage modifiers will completely ignore all damage
      reduction modifiers
    </Paragraph>,
  ];

  return (
    <CalculatorCard
      title="Effective HP Calculator"
      formInputComponentList={ehpInputComponents}
      descComponents={ehpDescriptionComponents}
      outputObjects={ehpOutputObjects}
    />
  );
}

export default EHPCalculator;
