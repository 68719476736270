/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  CompassOutlined,
  CalculatorOutlined,
  HomeOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { Menu, Layout, Divider } from 'antd';
import { Link } from 'react-router-dom';

import {
  calcEhpPath,
  calcBuffDurationPath,
  questTrackerPath,
  mapSelectorPath,
  questItemsPath,
  kitBuilderPath,
} from '../data/paths';
import CharacterSelect from './CharacterSelect';
const { SubMenu } = Menu;
const { Sider, Header } = Layout;

const items = [
  { label: 'Home', key: '1', to: '/', icon: <HomeOutlined /> },
  {
    label: 'Calculators',
    key: 'sub1',
    icon: <CalculatorOutlined />,
    children: [
      { label: 'Effective HP', key: '2', to: calcEhpPath },
      // { label: 'Buff Duration', key: '3', to: calcBuffDurationPath },
    ],
  },
  {
    label: 'Quests',
    key: 'sub2',
    icon: <CompassOutlined />,
    children: [
      { label: 'Quest Tracker', key: '4', to: questTrackerPath },
      { label: 'Quest Items', key: '5', to: questItemsPath },
    ],
  },
  {
    label: 'Maps',
    key: '6',
    icon: <EnvironmentOutlined />,
    to: mapSelectorPath,
  },
  /*{
    label: 'Kit Builder',
    key: '7',
    to: kitBuilderPath,
    icon: <EnvironmentOutlined />,
  },*/
];

const renderMenuItems = (menuItems) =>
  menuItems.map((item) => {
    if (isMobile && item.label === 'Maps') {
      return <></>;
    }
    if (item.children) {
      const selectedChildKey = renderMenuItems(item.children);

      return (
        <SubMenu key={item.key} icon={item.icon} title={item.label}>
          {selectedChildKey}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={item.key} icon={item.icon}>
        <Link to={item.to}>{item.label}</Link>
      </Menu.Item>
    );
  });

// eslint-disable-next-line react/prop-types
function DadMenu({
  characters,
  setAndStoreCharacters,
  selectedCharacter,
  setAndStoreSelectedCharacter,
  menuCollapsed,
  setMenuCollapsed,
}) {
  const location = useLocation();
  const findSelectedKey = (items, pathname) => {
    for (const item of items) {
      if (item.children) {
        const selectedKey = findSelectedKey(item.children, pathname);
        if (selectedKey) return selectedKey;
      } else if (item.to === pathname) {
        return item.key;
      }
    }
    return null;
  };
  const selectedKey = findSelectedKey(items, location.pathname);

  if (isMobile) {
    return (
      <Header
        theme="dark"
        style={{
          width: '100vw',
          display: 'flex',
          alignItems: 'left',
          zIndex: 100,
          backgroundColor: '#333c49',
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          defaultSelectedKeys={selectedKey ? [selectedKey] : []}
          mode="horizontal"
          style={{ backgroundColor: '#333c49' }}
        >
          {renderMenuItems(items)}
        </Menu>
      </Header>
    );
  }
  return (
    <>
      <div
        style={{
          position: 'fixed',
          overflowY: 'none',
          height: menuCollapsed ? 80 : 120,
          bottom: 50,
          left: 0,
          zIndex: 100000,
          width: menuCollapsed ? 80 : 200, // Set a fixed height for the Sider
          display: 'flex',
          alignItems: 'center', // Vertically centers the content
          justifyContent: 'center', // Horizontally centers the content
          transition: 'all 0.3s ease', // Updated transition
        }}
      >
        <CharacterSelect
          characters={characters}
          setAndStoreCharacters={setAndStoreCharacters}
          selectedCharacter={selectedCharacter}
          setAndStoreSelectedCharacter={setAndStoreSelectedCharacter}
          menuCollapsed={menuCollapsed}
        />
      </div>

      <Sider
        theme="light"
        collapsible
        collapsed={menuCollapsed}
        onCollapse={(value) => setMenuCollapsed(!menuCollapsed)}
        style={{ zIndex: 100, paddingBottom: menuCollapsed ? 80 : 120 }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          defaultSelectedKeys={selectedKey ? [selectedKey] : []}
          mode="inline"
          style={{
            position: 'fixed',
            overflowY: 'auto',
            height: '100vh',
            width: menuCollapsed ? 80 : 200, // Set a fixed height for the Sider
          }}
        >
          {renderMenuItems(items)}
        </Menu>
      </Sider>
    </>
  );
}
export default DadMenu;
