/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Select,
  Typography,
  Row,
  Col,
  Switch,
  Checkbox,
  Divider,
  Slider,
  Collapse,
  Button,
  Menu,
  Input,
  Tabs,
} from 'antd';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import getImageId from './imageIds.js';
import { getMapIcons } from '../../data/icons/mapIcons.js';
import idToIcon from '../../data/idToIcon.json';
import MapSettings from './mapSettings.js';
import MapActiveQuests from './MapActiveQuests.js';
import MapSelection from './mapSelection.js';

const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

function MapLegend({
  mapType,
  setMapType,
  mapName,
  setMapName,
  paths,
  isHr,
  setIsHr,
  setActiveMarkers,
  activeMarkers,
  isCollapsed,
  zoom,
  setZoom,
  mapIconScale,
  setMapIconScale,
  filteredMaps,
  setFilteredMaps,
  setSectionSelection,
  sectionSelection,
  iconStates,
  setIconStates,
  setSelectedIconNames,
  selectedIconNames,
  moduleIconScale,
  setModuleIconScale,
  selectedCharacter,
  characters,
  setAndStoreCharacters,
}) {
  const [legendScale, setLegendScale] = useState(
    localStorage.getItem('legendScale') || 32,
  );
  const [searchTerm, setSearchTerm] = useState('');

  const allSections = Object.keys(iconStates).map((category) => {
    return category;
  });

  const [activePanelKeys, setActivePanelKeys] = useState([]);
  useEffect(() => {
    if (searchTerm) {
      setActivePanelKeys(allSections);
    } else {
      setActivePanelKeys([]);
    }
  }, [searchTerm]);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/Maps',
      title: 'Maps',
    });
  }, []);

  const processIconStates = (iconStates) => {
    const processed = {};

    for (const [category, iconsOrSubcategories] of Object.entries(iconStates)) {
      if (Array.isArray(iconsOrSubcategories)) {
        processed[category] = iconsOrSubcategories;
      } else {
        if (!processed[category]) {
          processed[category] = {};
        }
        for (const [subCategory, icons] of Object.entries(
          iconsOrSubcategories,
        )) {
          processed[category][subCategory] = icons;
        }
      }
    }

    return processed;
  };

  const selectAllIcons = () => {
    setIconStates((prevIconStates) => {
      const newIconStates = { ...prevIconStates };
      const allIconNames = new Set();

      Object.keys(newIconStates).forEach((category) => {
        if (
          typeof newIconStates[category] === 'object' &&
          !Array.isArray(newIconStates[category])
        ) {
          Object.keys(newIconStates[category]).forEach((subCategory) => {
            newIconStates[category][subCategory] = newIconStates[category][
              subCategory
            ].map((icon) => {
              allIconNames.add(idToIcon[icon.object_name]);
              return { ...icon, visible: true };
            });
          });
        } else {
          newIconStates[category] = newIconStates[category].map((icon) => {
            allIconNames.add(idToIcon[icon.object_name]);
            return { ...icon, visible: true };
          });
        }
      });

      setSelectedIconNames(allIconNames);

      return newIconStates;
    });

    setSectionSelection((prevSelection) => {
      const newSelection = { ...prevSelection };
      Object.keys(newSelection).forEach((section) => {
        newSelection[section] = true;
      });
      return newSelection;
    });
  };

  const deselectAllIcons = () => {
    setIconStates((prevIconStates) => {
      const newIconStates = { ...prevIconStates };
      Object.keys(newIconStates).forEach((category) => {
        if (
          typeof newIconStates[category] === 'object' &&
          !Array.isArray(newIconStates[category])
        ) {
          Object.keys(newIconStates[category]).forEach((subCategory) => {
            newIconStates[category][subCategory] = newIconStates[category][
              subCategory
            ].map((icon) => ({
              ...icon,
              visible: false,
            }));
          });
        } else {
          newIconStates[category] = newIconStates[category].map((icon) => ({
            ...icon,
            visible: false,
          }));
        }
      });
      setSelectedIconNames(new Set());
      return newIconStates;
    });

    setSectionSelection((prevSelection) => {
      const newSelection = { ...prevSelection };
      Object.keys(newSelection).forEach((section) => {
        newSelection[section] = false;
      });
      return newSelection;
    });
  };

  const handleIconSelectionChange = (iconType, icon, subCategory = null) => {
    const iconName = idToIcon[icon.object_name];

    setIconStates((prevIconStates) => {
      const newIconStates = { ...prevIconStates };
      const isSelected = subCategory
        ? prevIconStates[iconType][subCategory].find(
            (item) => item.object_name === icon.object_name,
          ).visible
        : prevIconStates[iconType].find(
            (item) => item.object_name === icon.object_name,
          ).visible;

      if (subCategory) {
        newIconStates[iconType][subCategory] = newIconStates[iconType][
          subCategory
        ].map((item) => {
          if (idToIcon[item.object_name] === idToIcon[icon.object_name]) {
            return { ...item, visible: !isSelected };
          }
          return item;
        });
      } else {
        newIconStates[iconType] = newIconStates[iconType].map((item) => {
          if (idToIcon[item.object_name] === idToIcon[icon.object_name]) {
            return { ...item, visible: !isSelected };
          }
          return item;
        });
      }

      setSelectedIconNames((prevSelectedIconNames) => {
        const newSet = new Set(prevSelectedIconNames);
        if (isSelected) {
          newSet.delete(iconName);
        } else {
          newSet.add(iconName);
        }
        return newSet;
      });

      const updatedMarkers = [];
      Object.values(newIconStates).forEach((iconCategory) => {
        if (typeof iconCategory === 'object' && !Array.isArray(iconCategory)) {
          Object.values(iconCategory).forEach((subCategoryIcons) => {
            subCategoryIcons.forEach((icon) => {
              if (icon.visible) {
                updatedMarkers.push(icon.marker);
              }
            });
          });
        } else {
          iconCategory.forEach((icon) => {
            if (icon.visible) {
              updatedMarkers.push(icon.marker);
            }
          });
        }
      });
      setActiveMarkers(updatedMarkers);

      return newIconStates;
    });
  };
  const toggleSection = (sectionType, subCategory = null) => {
    setSectionSelection((prevSelection) => ({
      ...prevSelection,
      [sectionType]: !prevSelection[sectionType],
    }));

    setIconStates((prevIconStates) => {
      const newIconStates = { ...prevIconStates };
      const shouldSelectAll = !sectionSelection[sectionType];

      if (subCategory) {
        const icons = newIconStates[sectionType][subCategory];
        newIconStates[sectionType][subCategory] = icons.map((icon) => {
          const iconName = idToIcon[icon.object_name];
          if (shouldSelectAll) {
            setSelectedIconNames((prevSelected) =>
              new Set(prevSelected).add(iconName),
            );
          } else {
            setSelectedIconNames((prevSelected) => {
              const newSet = new Set(prevSelected);
              newSet.delete(iconName);
              return newSet;
            });
          }
          return { ...icon, visible: shouldSelectAll };
        });
      } else {
        if (!Array.isArray(newIconStates[sectionType])) {
          Object.entries(newIconStates[sectionType]).forEach(
            ([subCatKey, icons]) => {
              newIconStates[sectionType][subCatKey] = icons.map((icon) => {
                const iconName = idToIcon[icon.object_name];
                if (shouldSelectAll) {
                  setSelectedIconNames((prevSelected) =>
                    new Set(prevSelected).add(iconName),
                  );
                } else {
                  setSelectedIconNames((prevSelected) => {
                    const newSet = new Set(prevSelected);
                    newSet.delete(iconName);
                    return newSet;
                  });
                }
                return { ...icon, visible: shouldSelectAll };
              });
            },
          );
        } else {
          newIconStates[sectionType] = newIconStates[sectionType].map(
            (icon) => {
              const iconName = idToIcon[icon.object_name];
              if (shouldSelectAll) {
                setSelectedIconNames((prevSelected) =>
                  new Set(prevSelected).add(iconName),
                );
              } else {
                setSelectedIconNames((prevSelected) => {
                  const newSet = new Set(prevSelected);
                  newSet.delete(iconName);
                  return newSet;
                });
              }
              return { ...icon, visible: shouldSelectAll };
            },
          );
        }
      }

      return newIconStates;
    });
  };

  const renderSvg = (icon) => {
    const imageId = getImageId(icon.object_name);
    if (icon.object_name.startsWith('MN_')) {
      return null;
    }

    const svgIcon = getMapIcons()[imageId]
      ? getMapIcons()[imageId]
      : getMapIcons()['mob'];

    const widthRegex = /width="(\d+)(px)?"/;
    const heightRegex = /height="(\d+)(px)?"/;

    function scaleSvgIcon(svg, scale) {
      return svg
        .replace(widthRegex, `width="${scale}"`)
        .replace(heightRegex, `height="${scale}"`);
    }

    const scaledSvgIcon = scaleSvgIcon(svgIcon, legendScale);
    return scaledSvgIcon;
  };

  const renderSections = (processedIconStates) => {
    return Object.keys(iconStates).map((category) =>
      renderIconSelection(category, processedIconStates[category]),
    );
  };
  const renderIconSelection = (iconType, icons, subCategory = null) => {
    if (!icons) {
      return null;
    }

    const uniqueKeys = new Set();

    const renderIcons = (iconsArray, subCategory = null) => {
      return iconsArray
        .filter((icon) => {
          const iconName = idToIcon[icon.object_name];
          if (
            !iconName ||
            uniqueKeys.has(iconName) ||
            !iconName.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return false;
          }
          uniqueKeys.add(iconName);
          return true;
        })
        .sort((a, b) => {
          const nameA = idToIcon[a.object_name].toUpperCase();
          const nameB = idToIcon[b.object_name].toUpperCase();
          return nameA.localeCompare(nameB);
        })
        .map((icon) => {
          const iconState = Array.isArray(icons)
            ? icons.find((item) => item.object_name === icon.object_name)
            : Object.keys(icons).reduce((acc, subCat) => {
                if (acc) return acc;
                return icons[subCat].find(
                  (item) => item.object_name === icon.object_name,
                );
              }, null);

          const isVisible = iconState ? iconState.visible : false;

          const handleClick = () => {
            handleIconSelectionChange(iconType, icon, subCategory);
          };

          return (
            <Col xs={24} sm={12} md={12} lg={12} xl={12} key={icon.object_name}>
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleClick}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: renderSvg(icon) }}
                  className={isVisible ? 'selected-icon' : 'unselected-icon'}
                  style={{
                    marginLeft: '2px',
                  }}
                ></div>
                <div
                  className={isVisible ? 'selected-text' : 'unselected-text'}
                  style={{
                    marginLeft: 8,
                    top: '-20px',
                    position: 'relative',
                    marginBottom: iconType === 'Modules' ? '-10px' : '-30px',
                    marginTop: iconType === 'Modules' ? '10px' : '0px',
                  }}
                >
                  {idToIcon[icon.object_name]}
                </div>
              </label>
            </Col>
          );
        });
    };

    const renderedIcons = Array.isArray(icons) ? renderIcons(icons) : null;

    if (Array.isArray(icons) && renderedIcons.length === 0) {
      return null;
    }

    const renderedSubCategories = !Array.isArray(icons)
      ? Object.keys(icons)
          .map((subCat) => {
            const renderedSubCatIcons = renderIcons(icons[subCat], subCat);
            if (renderedSubCatIcons.length === 0) {
              return null;
            }

            return (
              <React.Fragment key={subCat}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    fontSize: '20px',
                    marginBottom: '20px',
                    padding: '10px',
                    borderRadius: '8px',
                  }}
                >
                  <Divider style={{ marginTop: 0, marginBottom: 0 }}>
                    <Button
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSection(iconType, subCat ? subCat : null);
                      }}
                    >
                      {subCat}
                    </Button>
                  </Divider>
                </div>
                <Row gutter={[16, 16]}>{renderedSubCatIcons}</Row>
              </React.Fragment>
            );
          })
          .filter(Boolean)
      : null;

    if (!Array.isArray(icons) && renderedSubCategories.length === 0) {
      return null;
    }

    return (
      <Panel
        header={`${iconType.replace(/([A-Z])/g, ' $1').trim()}${
          subCategory
            ? ` - ${subCategory.replace(/([A-Z])/g, ' $1').trim()}`
            : ''
        }`}
        isActive={activePanelKeys.includes(iconType)}
        key={iconType}
        extra={
          <Button
            onClick={(e) => {
              e.stopPropagation();
              toggleSection(iconType, subCategory ? subCategory : null);
            }}
          >
            {sectionSelection[iconType] ? 'Deselect All' : 'Select All'}
          </Button>
        }
      >
        {Array.isArray(icons) ? (
          <Row gutter={[16, 16]}>{renderedIcons}</Row>
        ) : (
          renderedSubCategories
        )}
      </Panel>
    );
  };
  const processedIconStates = processIconStates(iconStates);

  return (
    <Menu
      className="my-scrollbar"
      style={{
        position: 'fixed',
        top: 0,
        padding: isCollapsed ? 0 : 16,
        right: 0,
        overflowY: 'auto',
        width: isCollapsed ? 0 : 400,
        height: '100vh',
        zIndex: 20,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 16,
          right: 16,
        }}
      ></div>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'Map Info',
            key: '1',
            children: (
              <React.Fragment>
                <MapSelection
                  paths={paths}
                  mapType={mapType}
                  setMapType={setMapType}
                  mapName={mapName}
                  setMapName={setMapName}
                  isHr={isHr}
                  setIsHr={setIsHr}
                  filteredMaps={filteredMaps}
                />
                <Divider>Interactive Markers</Divider>
                <Button className="button-margin" onClick={selectAllIcons}>
                  Select All
                </Button>
                <Button className="button-margin" onClick={deselectAllIcons}>
                  Deselect All
                </Button>
                <div style={{ marginTop: '1.1vh' }}></div>
                <Input
                  className="button-margin"
                  placeholder="Search for Interactive Map Markers"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  allowClear
                />
                <div style={{ marginTop: '1vh' }}></div>
                {searchTerm ? (
                  <Collapse activeKey={activePanelKeys ? activePanelKeys : []}>
                    {renderSections(processedIconStates)}
                  </Collapse>
                ) : (
                  <Collapse>{renderSections(processedIconStates)}</Collapse>
                )}
              </React.Fragment>
            ),
          },
          {
            label: 'Active Quests',
            key: '2',
            children: (
              <React.Fragment>
                <MapSelection
                  paths={paths}
                  mapType={mapType}
                  setMapType={setMapType}
                  mapName={mapName}
                  setMapName={setMapName}
                  isHr={isHr}
                  setIsHr={setIsHr}
                  filteredMaps={filteredMaps}
                />
                <Divider>Active Quests</Divider>
                <MapActiveQuests
                  mapName={mapType}
                  idk={mapName}
                  characters={characters}
                  setAndStoreCharacters={setAndStoreCharacters}
                  selectedCharacter={selectedCharacter}
                />
              </React.Fragment>
            ),
          },

          {
            label: 'Settings',
            key: '3',
            children: (
              <React.Fragment>
                <MapSettings
                  mapIconScale={mapIconScale}
                  setMapIconScale={setMapIconScale}
                  legendScale={legendScale}
                  setLegendScale={setLegendScale}
                  moduleIconScale={moduleIconScale}
                  setModuleIconScale={setModuleIconScale}
                />
                <Divider>Map Controls</Divider>
                <Title level={5}>Zoom</Title>
                <Slider
                  style={{
                    width: '300px',
                  }}
                  trackStyle={{ backgroundColor: '#76ABAE' }}
                  handleStyle={{ borderColor: '#76ABAE' }}
                  min={-6}
                  max={0}
                  step={0.1}
                  value={zoom}
                  tooltip={{ title: 'Zoom' }}
                  onChange={(value) => setZoom(value)}
                />
              </React.Fragment>
            ),
          },
        ]}
      />
    </Menu>
  );
}

MapLegend.propTypes = {
  mapType: PropTypes.string,
  setMapType: PropTypes.func.isRequired,
  mapName: PropTypes.string,
  setMapName: PropTypes.func.isRequired,
  paths: PropTypes.array.isRequired,
  isHr: PropTypes.bool.isRequired,
  setIsHr: PropTypes.func.isRequired,
  iconsProp: PropTypes.array.isRequired,
  setActiveMarkers: PropTypes.func.isRequired,
  activeMarkers: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  mapIconScale: PropTypes.number.isRequired,
  setMapIconScale: PropTypes.func.isRequired,
  iconExtractions: PropTypes.array.isRequired,
  filteredMaps: PropTypes.array.isRequired,
  setFilteredMaps: PropTypes.func.isRequired,
  setSectionSelection: PropTypes.func.isRequired,
  sectionSelection: PropTypes.object.isRequired,
  iconStates: PropTypes.object.isRequired,
  setIconsToPlot: PropTypes.func.isRequired,
  setIconStates: PropTypes.func.isRequired,
  setSelectedIconNames: PropTypes.func.isRequired,
  selectedIconNames: PropTypes.object.isRequired,
  moduleIconScale: PropTypes.number.isRequired,
  setModuleIconScale: PropTypes.func.isRequired,
};

export default MapLegend;
