/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import MapDisplay from './MapDisplay.js';
import MapLegend from './mapLegend.js';
import { Row, Col } from 'antd';
import axios from 'axios';
import getImageId from './imageIds.js';
import { getMapIcons } from '../../data/icons/mapIcons.js';
import idToIcon from '../../data/idToIcon.json';
import completeSpawnRateData from '../../data/complete_drop_rates.json';
import completeSpawnData from '../../data/complete_spawn_rates.json';
import 'leaflet/dist/leaflet.css';

const mapContext = require.context('../../../public/data/maps', true, /\.png$/);
const paths = mapContext.keys().map((path) => path.replace('./', ''));
const widthRegex = /width="(\d+)(px)?"/;
const heightRegex = /height="(\d+)(px)?"/;
const initialMapType = localStorage.getItem('mapType') || 'Crypt';
const initialMapName =
  localStorage.getItem('mapName') || 'Crypt/Normal/Crypt_01/Crypt_01_N_P.json';

const initialIsHr = localStorage.getItem('isHr') === 'true';
const initialFilterIndex = parseInt(localStorage.getItem('filterIndex')) || 0;
const initialZoom = parseFloat(localStorage.getItem('zoom')) || -5;
const initialSelectedIconNames = new Set(
  JSON.parse(localStorage.getItem('selectedIconNames') || '[]'),
);
const MapMain = ({ selectedCharacter, characters, setAndStoreCharacters }) => {
  const [updateVersion, setUpdateVersion] = useState(0);
  const [curMap, setCurMap] = useState('');
  const [mapType, setMapType] = useState(initialMapType);
  const [mapName, setMapName] = useState(initialMapName);
  const [filteredMaps, setFilteredMaps] = useState([]);
  const [filterIndex, setFilterIndex] = useState(initialFilterIndex);
  const [selectedIconNames, setSelectedIconNames] = useState(
    initialSelectedIconNames,
  );
  const [activeMarkers, setActiveMarkers] = useState([]);
  const [iconStates, setIconStates] = useState([{}]);
  const [iconsToPlot, setIconsToPlot] = useState([]);
  const [isHr, setIsHr] = useState(initialIsHr);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [zoom, setZoom] = useState(initialZoom);
  const [cacheVersion, setCacheVersion] = useState(0);
  const [sectionSelection, setSectionSelection] = useState({
    Shrines: false,
    Monsters: false,
    Chests: false,
    Resources: false,
    LootSpawns: false,
    Miscellaneous: false,
    Modules: false,
    'Extractions / Player Spawns': false,
  });

  const [mapIconScale, setMapIconScale] = useState(
    localStorage.getItem('mapIconScale') || 32,
  );
  const [moduleIconScale, setModuleIconScale] = useState(
    localStorage.getItem('moduleIconScale') || 32,
  );
  const svgCache = useRef({});
  useEffect(() => {
    localStorage.setItem('moduleIconScale', moduleIconScale);
    localStorage.setItem('filterIndex', filterIndex);
    localStorage.setItem('mapType', mapType);
    localStorage.setItem('mapName', mapName);
    localStorage.setItem('isHr', isHr.toString());
    localStorage.setItem('zoom', zoom.toString());
    localStorage.setItem(
      'selectedIconNames',
      JSON.stringify(Array.from(selectedIconNames)),
    );
  }, [mapType, mapName, isHr, zoom, selectedIconNames]);
  const storeSvgString = (icon, svgString) => {
    svgCache.current[`${icon}_${cacheVersion}`] = svgString;
  };

  const getCachedSvgString = (icon) => {
    return svgCache.current[`${icon}_${cacheVersion}`];
  };
  const getSpawnRate = async (spawnerPath) => {
    const spawnData = completeSpawnData.find((data) =>
      data.Name.includes(spawnerPath),
    );

    return spawnData;
  };

  const getDropRate = async (dropRatePath) => {
    if (!dropRatePath) return null;

    const spawnData = completeSpawnRateData.find((data) =>
      data.Name.includes(dropRatePath),
    );

    return spawnData;
  };
  const generateSvgString = (icon) => {
    const cacheKey = `${icon}_${cacheVersion}`;
    const cachedSvg = getCachedSvgString(cacheKey);

    if (cachedSvg) {
      return cachedSvg;
    }

    const imageId = getImageId(icon);
    const iconType = icon;
    const iconKey = idToIcon[icon];
    let svgString;

    if (iconType.startsWith('MN_')) {
      svgString = getMapIcons()[imageId];
      if (!iconKey) return;
      let parser = new DOMParser();
      let svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
      let textElement = svgDoc.querySelector('text');
      if (textElement) {
        textElement.textContent = iconKey;
      }
      let modifiedSvgString = new XMLSerializer().serializeToString(svgDoc);
      storeSvgString(icon, modifiedSvgString);
      return modifiedSvgString;
    } else {
      svgString = getMapIcons()[imageId]
        ? getMapIcons()[imageId]
        : getMapIcons()['mob'];
      storeSvgString(icon, svgString);
      return svgString;
    }
  };

  const loadMapData = async () => {
    let path;
    if (isHr) {
      path = `/data/maps/${mapName.replace('_P.png', '_HR_P.json')}`;
    } else {
      path = `/data/maps/${mapName.replace('_P.png', '_N_P.json')}`;
    }
    if (path.includes('SnowVer')) {
      path = path.replace('_SnowVer', '');
      path = path.replace('IceCave_', 'IceCavern_');
    }
    try {
      const response = await axios.get(path);
      return response.data;
    } catch (error) {
      console.error('Error loading map data:', error);
      return null;
    }
  };

  const getMapData = async (path) => {
    if (!mapName) {
      return;
    }
    if (isHr) {
      path = `/data/maps/${mapName.replace('_P.png', '_HR_P.json')}`;
    } else {
      path = `/data/maps/${mapName.replace('_P.png', '_N_P.json')}`;
    }
    const mapData = await loadMapData(path);
    separateIcons(mapData);
  };

  const setMapImage = () => {
    const pathToImage = `/data/maps/${mapName}`;
    setCurMap(pathToImage);
  };

  const setMapData = async () => {
    await getMapData();
  };

  const setMap = async () => {
    setMapImage();
    setMapData();
  };

  const separateIcons = async (mapData) => {
    if (!mapData) return;

    const categoryPatterns = {
      Shrines: ['Statue0', 'Id_Props_AltarOfSacrifice'],
      'Extractions / Player Spawns': {
        Extractions: [
          '^Id_Extraction',
          'BP_PedestalPortal',
          'BP_DungeonEscape',
          'BP_FixedStairEscape',
          'BP_DungeonDown',
          'BP_FixedStairDown',
          'BP_CaveExit',
          'BP_FixedElevator',
        ],
        'Player Spawns': ['DCPlayerStart'],
      },
      Monsters: {
        Bosses: [
          'Lich',
          'Warlord',
          'GhostKing',
          'FrostWyvern',
          'Troll',
          'Cyclops',
          'UndeadKnight',
          'LavaMonster',
          'SpectralKnight',
        ],
        'Mini Bosses': [
          'Wraith',
          'Centipede',
          'Yeti',
          'IceHarpy',
          'FrostGiantBerserker',
          'FrostGiantShielder',
          'Wendigo',
          'FrostImp',
          'FrostDemon',
          'Centaur',
          'Berserker',
          'SkeletonChampion',
          'Cockatrice',
          'GiantWorm',

          'RuinsGolem',
        ],
        Mobs: [
          'Monster',
          'Wisp',
          'DireWolf',
          'DeathBeetle',
          'GiantDragonfly',
          'GiantBat',
          'DeathSkull',
          'Ranged',
          'Crossbowman',
          'Archer',
          'Bolas',
          'Mage',
          'SpiderPot',
          'Id_Monster_Slime',
        ],
      },
      Chests: {
        'High Tier': [
          'Id_Props_Coffin_06',
          'OrnateChest',
          'ChestSpecial',
          
          'MarvelousChest',
          'Hoard01',
          'Hoard',
        ],
        'Low Tier': ['Chest', 'Coffin', 'Id_Props_SkeletonBones', 'StoneTomb'],
      },
      Resources: {
        Ores: ['Ore'],
        Herbs: ['Flower', 'Wardweed', 'Lifeleaf'],
      },
      Miscellaneous: {
        Interactables: ['Door', 'Lever', 'Id_Props_StatueLever'],
        Traps: ['WallSpike', 'FloorSpikes', 'TrackAxe'],
        Keys: ['SkullKey'],
        Props: ['Id_Props_'],
      },
      LootSpawns: ['LootDrop'],
      Modules: ['^MN_'],
    };

    const initializeIconsStructure = (patterns) => {
      const result = {};
      for (const [key, value] of Object.entries(patterns)) {
        if (typeof value === 'object' && !Array.isArray(value)) {
          result[key] = initializeIconsStructure(value);
        } else {
          result[key] = [];
        }
      }
      return result;
    };

    const icons = initializeIconsStructure(categoryPatterns);

    const iconCoordDict = {};

    const matchPattern = (patterns, name) =>
      patterns.some((pattern) => new RegExp(pattern).test(name));

    const getCategoryAndSubcategory = (name) => {
      for (const [key, patterns] of Object.entries(categoryPatterns)) {
        if (typeof patterns === 'object' && !Array.isArray(patterns)) {
          for (const [subKey, subPatterns] of Object.entries(patterns)) {
            if (matchPattern(subPatterns, name))
              return { category: key, subcategory: subKey };
          }
        } else if (matchPattern(patterns, name)) {
          return { category: key, subcategory: null };
        }
      }
      return { category: 'Miscellaneous', subcategory: 'Props' };
    };

    for (const [index, icon] of mapData.entries()) {
      if (!idToIcon[icon.object_name]) continue;

      const { category, subcategory } = getCategoryAndSubcategory(
        icon.object_name,
      );

      const { X, Y, Z } = icon.object_location;
      const { scaledAndShiftedX, scaledAndShiftedY } = adjustCoordinates(
        X,
        Y,
        mapType,
        Z,
      );
      const svgString = getSvgString(icon.object_name);
      const newPosition = getPosition(
        icon.object_location,
        scaledAndShiftedX,
        scaledAndShiftedY,
        Z,
      );
      const spawnableItems = await getSpawnableItems(
        icon.object_name,
        icon.object_name,
      );

      const coordKey = `${scaledAndShiftedX},${scaledAndShiftedY},${Z}`;
      if (!iconCoordDict[coordKey]) iconCoordDict[coordKey] = [];

      iconCoordDict[coordKey].push({
        ...icon,
        index,
        svgString,
        newPosition,
        spawnableItems,
        category,
        subcategory,
      });
    }

    for (const coordKey in iconCoordDict) {
      const iconsAtCoord = iconCoordDict[coordKey];
      const allIconIds = iconsAtCoord.map((icon) => icon.object_name);
      const tooltip = generateTooltip(
        allIconIds,
        iconsAtCoord[0].spawnableItems,
        iconsAtCoord[0].object_location.Z,
      );

      for (const iconData of iconsAtCoord) {
        const markerObj = createMarkerObject(
          iconData.index,
          iconData.object_name,
          iconData.svgString,
          iconData.newPosition,
          tooltip,
        );
        const iconEntry = {
          ...iconData,
          visible: selectedIconNames.has(idToIcon[iconData.object_name]),
          marker: markerObj,
        };

        if (iconData.subcategory) {
          icons[iconData.category][iconData.subcategory].push(iconEntry);
        } else {
          icons[iconData.category].push(iconEntry);
        }
      }
    }
    setIconStates(icons);
  };

  const adjustCoordinates = (X, Y, mapType, Z) => {
    let scaledAndShiftedX, scaledAndShiftedY;
    if (mapType.includes('Ruins')) {
      scaledAndShiftedX = X + 1300;
      scaledAndShiftedY = Y + 700;
    } else if (mapType.includes('Crypt')) {
      scaledAndShiftedX = X + 1200;
      scaledAndShiftedY = Y + 500;
    } else if (mapType.includes('GoblinCave')) {
      scaledAndShiftedX = X + 1200;
      scaledAndShiftedY = Y + 500;
    } else if (mapType.includes('IceCave')) {
      scaledAndShiftedX = X * 1 + 1400;
      scaledAndShiftedY = Y + 700;
    } else if (mapType.includes('Inferno') || mapType.includes('IceAbyss')) {
      scaledAndShiftedX = X * 1.8 + 1200;
      scaledAndShiftedY = Y * 1.8 + 400;
    }
    return { scaledAndShiftedX, scaledAndShiftedY, Z };
  };
  const getSvgString = (iconId) => {
    if (!svgCache[iconId]) {
      svgCache[iconId] = generateSvgString(iconId);
    }
    return svgCache[iconId];
  };
  const getPosition = (
    objectLocation,
    scaledAndShiftedX,
    scaledAndShiftedY,
    Z,
  ) => {
    return objectLocation
      ? [scaledAndShiftedX, scaledAndShiftedY, Z]
      : [0, 0, 0];
  };

  const getSpawnableItems = async (iconId, objectName) => {
    const spawnerName = objectName.replace('Id', 'Id_Spawner');
    const rates = await getSpawnRate(spawnerName);

    if (!rates || !rates.Properties) return [];

    if (
      rates.Properties.SpawnerItemArray[0].ItemHolderLootDropRateId
        .AssetPathName
    ) {
      return await processLootDropRates(rates, iconId);
    } else {
      return processSpawnerItemArray(rates, iconId);
    }
  };
  const processLootDropRates = async (rates, iconId) => {
    const dropRateData = await getDropRate(
      rates.Properties.SpawnerItemArray[0].ItemHolderLootDropRateId.AssetPathName.split(
        '.',
      )[1],
    );

    if (!dropRateData || !iconId.startsWith('Id_LootDrop')) return [];

    return dropRateData.Properties.LootDropRateItemArray.filter(
      (item) => item.DropRate !== 0,
    ).map((item) => {
      const luckGrade = getLuckGrade(item.LuckGrade);
      const dropRate = `${Math.floor((item.DropRate / 100000) * 100)}%`;
      return [luckGrade, dropRate];
    });
  };
  const processSpawnerItemArray = (rates, iconId) => {
    const { SpawnerItemArray } = rates.Properties;

    return SpawnerItemArray.map((item) => {
      const assetPathName =
        item.PropsId?.AssetPathName ||
        item.MonsterId?.AssetPathName ||
        item.ItemHolderLootDropRateId?.AssetPathName ||
        item.ItemHolderLootDropId?.AssetPathName;

      if (item.SpawnRate > 0) {
        const name = getItemName(item, assetPathName, iconId);
        return [`${Math.floor((item.SpawnRate / 10000) * 100)}%`, name];
      }
      return null;
    }).filter(Boolean);
  };
  const getLuckGrade = (LuckGrade) => {
    const luckGrades = [
      'Junk',
      'Poor',
      'Common',
      'Uncommon',
      'Rare',
      'Epic',
      'Legendary',
      'Unique',
    ];
    return luckGrades[LuckGrade] || '';
  };
  const getItemName = (item, assetPathName, iconId) => {
    if (item.SpawnRate === 10000) {
      return idToIcon[iconId];
    } else if (assetPathName) {
      return assetPathName
        .split('/')
        .pop()
        .split('.')[0]
        .replace(/(ID_Lootdrop_Spawn_|Id_Monster_|Id_Props_)/, '')
        .replace(/HR[1-3]/g, '')
        .replace(/N[1-3]/g, '')
        .replace(/_/g, ' ')
        .replace(/([A-Z])/g, ' $1')
        .trim();
    }
    return null;
  };
  const generateTooltip = (iconIds, spawnableItems, Z) => {
    if (iconIds.some((id) => id.startsWith('MN_'))) {
      return null;
    }

    const iconList = iconIds.map((id) => `<b>${idToIcon[id]}</b>`).join('<br>');
    const header = iconList ? `${iconList}<br>` : '';

    const sortedItems = spawnableItems
      .filter(([rate, name]) => parseFloat(rate) !== 100)
      .sort((a, b) => parseFloat(b[0]) - parseFloat(a[0]));

    let tooltipContent = '';

    if (sortedItems.length) {
      tooltipContent = `<table><tr><th>Spawn Rate</th><th>Item</th></tr>${sortedItems
        .map(
          ([rate, name]) => `<tr><td><b>${rate}</b></td><td>${name}</td></tr>`,
        )
        .join('')}</table>`;
    }

    const zIndexText =
      Z !== undefined && Z !== null ? `<div>Height: ${Z}</div>` : '';

    return `<div>${header}${tooltipContent}${zIndexText}</div>`;
  };

  const createMarkerObject = (
    index,
    iconId,
    svgString,
    newPosition,
    markerTooltip,
  ) => {
    return {
      id: `marker-${index}`,
      iconId: iconId.startsWith('MN_')
        ? iconId + updateVersion
        : idToIcon[iconId] + updateVersion,
      customIcon: iconId.startsWith('MN_')
        ? svgString
            .replace(widthRegex, `width="${moduleIconScale * 4}"`)
            .replace(heightRegex, `height="${moduleIconScale * 4}"`)
        : svgString
            .replace(widthRegex, `width="${mapIconScale}"`)
            .replace(heightRegex, `height="${mapIconScale}"`),
      position: iconId.startsWith('MN_')
        ? [newPosition[0] - 800, newPosition[1], newPosition[2]]
        : [newPosition[0], newPosition[1], newPosition[2]],
      tooltip: markerTooltip,
      tooltipOptions: iconId.startsWith('MN_')
        ? null
        : {
            direction: 'center',
            permanent: true,
            opacity: 0.8,
            offset: [0, -20],
            className: 'leaflet-tooltip fixed-size-popup',
          },
    };
  };

  const mapChanged = () => {
    setMap();
  };

  const calculateFilterIndex = () => {
    const filter = paths.filter(
      (path) =>
        path.includes(mapType) && path.includes(isHr ? 'HighRoller' : 'Normal'),
    );
    const index = filter.indexOf(mapName);
    if (index === -1) {
      setMapName(filter[0]);
      setFilterIndex(0);
    } else {
      setFilterIndex(filter.indexOf(mapName));
    }
  };

  useEffect(() => {
    mapChanged();
    calculateFilterIndex();
  }, [mapName, isHr, mapType, curMap, updateVersion]);

  useEffect(() => {
    const filter = paths.filter(
      (path) =>
        path.includes(mapType) && path.includes(isHr ? 'HighRoller' : 'Normal'),
    );
    setFilteredMaps(filter);

    if (filter.length > 1) {
      setMapName(filter[filterIndex]);
    }
  }, [mapType, isHr, paths, setMapName]);

  const updateIconsToPlot = () => {
    const updatedIcons = [];
    const categoryOrder = [
      'Miscellaneous',
      'LootSpawns',
      'Monsters',
      'Resources',
      'Chests',
      'Shrines',
      'Extractions / Player Spawns',
      'Modules',
    ];
    categoryOrder.forEach((category) => {
      const iconCategory = iconStates[category];
      if (!iconCategory) return;

      if (Array.isArray(iconCategory)) {
        iconCategory.forEach((icon) => {
          if (icon.visible) {
            updatedIcons.push(icon.marker);
          }
        });
      } else {
        Object.values(iconCategory).forEach((subCategoryIcons) => {
          subCategoryIcons.forEach((icon) => {
            if (icon.visible) {
              updatedIcons.push(icon.marker);
            }
          });
        });
      }
    });

    setIconsToPlot(updatedIcons);
  };

  useEffect(() => {
    updateIconsToPlot();
  }, [iconStates]);
  return (
    <div>
      <Row>
        <div
          style={{
            overflow: 'auto',
            height: 'auto',
            width: 'auto',
            top: '2vh',
            position: 'fixed',
            zIndex: 1000,
          }}
        >
          <MapLegend
            mapType={mapType}
            setMapType={setMapType}
            mapName={mapName}
            setMapName={setMapName}
            paths={paths}
            isHr={isHr}
            setIsHr={setIsHr}
            setActiveMarkers={setActiveMarkers}
            activeMarkers={activeMarkers}
            isCollapsed={isCollapsed}
            zoom={zoom}
            setZoom={setZoom}
            mapIconScale={mapIconScale}
            setMapIconScale={setMapIconScale}
            filteredMaps={filteredMaps}
            setFilteredMaps={setFilteredMaps}
            setIconStates={setIconStates}
            sectionSelection={sectionSelection}
            setSectionSelection={setSectionSelection}
            iconStates={iconStates}
            setSelectedIconNames={setSelectedIconNames}
            selectedIconNames={selectedIconNames}
            setFilterIndex={setFilterIndex}
            filterIndex={filterIndex}
            moduleIconScale={moduleIconScale}
            setModuleIconScale={setModuleIconScale}
            characters={characters}
            setAndStoreCharacters={setAndStoreCharacters}
            selectedCharacter={selectedCharacter}
          />
        </div>
        <Col span={20}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '0vw',
              position: 'fixed',
              top: '10vh',
            }}
          >
            <MapDisplay
              curMap={curMap}
              setCurMap={setCurMap}
              iconsToPlot={iconsToPlot}
              setIconsToPlot={setIconsToPlot}
              zoom={zoom}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              setZoom={setZoom}
              mapIconScale={mapIconScale}
              setMapIconScale={setMapIconScale}
              filteredMaps={filteredMaps}
              setFilteredMaps={setFilteredMaps}
              paths={paths}
              setMapName={setMapName}
              setMapType={setMapType}
              updateVersion={updateVersion}
              setUpdateVersion={setUpdateVersion}
              moduleIconScale={moduleIconScale}
              setModuleIconScale={setModuleIconScale}
              iconStates={iconStates}
              setIconStates={setIconStates}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MapMain;
