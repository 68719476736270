import { Typography, Row, Col } from 'antd';
import React from 'react';

const { Title } = Typography;

function UnderConstruction() {
  return (
    <div>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Title level={2}>This page is currently under construction</Title>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Title level={4}>Thank you for your patience</Title>
      </Row>
    </div>
  );
}

export default UnderConstruction;
