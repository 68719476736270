export function calculatePhysicalPowerBonus(physicalPower) {
  let physicalPowerBonus = 0;

  if (physicalPower < 5) {
    physicalPowerBonus = -0.8 + 0.1 * Math.abs(physicalPower - 0);
  } else if (physicalPower < 7) {
    physicalPowerBonus = -0.3 + 0.05 * Math.abs(physicalPower - 5);
  } else if (physicalPower < 11) {
    physicalPowerBonus = -0.2 + 0.03 * Math.abs(physicalPower - 7);
  } else if (physicalPower < 15) {
    physicalPowerBonus = -0.08 + 0.02 * Math.abs(physicalPower - 11);
  } else if (physicalPower < 50) {
    physicalPowerBonus = 0 + 0.01 * Math.abs(physicalPower - 15);
  } else if (physicalPower < 100) {
    physicalPowerBonus = 0.35 + 0.005 * Math.abs(physicalPower - 50);
  }
  return (physicalPowerBonus * 100).toFixed(2);
}

export function calculateHealthRecoveryBonus(vigor) {
  let healthRecovery = 0;

  if (vigor < 5) {
    healthRecovery = -0.55 + 0.05 * Math.abs(vigor - 0);
  } else if (vigor < 15) {
    healthRecovery = -0.3 + 0.03 * Math.abs(vigor - 5);
  } else if (vigor < 25) {
    healthRecovery = 0 + 0.07 * Math.abs(vigor - 15);
  } else if (vigor < 35) {
    healthRecovery = 0.7 + 0.05 * Math.abs(vigor - 25);
  } else if (vigor < 84) {
    healthRecovery = 1.2 + 0.02 * Math.abs(vigor - 35);
  } else if (vigor < 85) {
    healthRecovery = 2.18 + 0.01 * Math.abs(vigor - 84);
  } else if (vigor < 86) {
    healthRecovery = 2.19 + 0.03 * Math.abs(vigor - 85);
  } else if (vigor < 100) {
    healthRecovery = 2.22 + 0.02 * Math.abs(vigor - 86);
  }

  return healthRecovery.toFixed(2);
}

export function calculateitemEquipSpeed(dexterity) {
  let itemEquipSpeed = 0;

  if (dexterity < 1) {
    itemEquipSpeed = -0.95 + 0 * Math.abs(dexterity - 0);
  } else if (dexterity < 2) {
    itemEquipSpeed = -0.95 + 0.04 * Math.abs(dexterity - 1);
  } else if (dexterity < 15) {
    itemEquipSpeed = -0.91 + 0.07 * Math.abs(dexterity - 2);
  } else if (dexterity < 35) {
    itemEquipSpeed = 0 + 0.05 * Math.abs(dexterity - 15);
  } else if (dexterity < 70) {
    itemEquipSpeed = 1 + 0.02 * Math.abs(dexterity - 35);
  } else if (dexterity < 100) {
    itemEquipSpeed = 1.7 + 0.01 * Math.abs(dexterity - 70);
  }

  return (itemEquipSpeed * 100).toFixed(2);
}

export function calculateMagicalPowerBonus(magicalPower) {
  let magicalPowerBonus = 0;

  if (magicalPower < 1) {
    magicalPowerBonus = -0.9 + 0 * Math.abs(magicalPower - 0);
  } else if (magicalPower < 5) {
    magicalPowerBonus = -0.9 + 0.1 * Math.abs(magicalPower - 1);
  } else if (magicalPower < 15) {
    magicalPowerBonus = -0.5 + 0.05 * Math.abs(magicalPower - 5);
  } else if (magicalPower < 21) {
    magicalPowerBonus = 0 + 0.025 * Math.abs(magicalPower - 15);
  } else if (magicalPower < 40) {
    magicalPowerBonus = 0.15 + 0.02 * Math.abs(magicalPower - 21);
  } else if (magicalPower < 50) {
    magicalPowerBonus = 0.53 + 0.01 * Math.abs(magicalPower - 40);
  } else if (magicalPower < 100) {
    magicalPowerBonus = 0.63 + 0.005 * Math.abs(magicalPower - 50);
  }

  return (magicalPowerBonus * 100).toFixed(2);
}

export function calculateMoveSpeed(agility) {
  let moveSpeed = 0;

  if (agility < 15) {
    moveSpeed = -30 + 2 * Math.abs(agility - 0);
  } else if (agility < 45) {
    moveSpeed = 0 + 1 * Math.abs(agility - 15);
  } else if (agility < 46) {
    moveSpeed = 30 + 0 * Math.abs(agility - 45);
  } else if (agility < 47) {
    moveSpeed = 30 + 1 * Math.abs(agility - 46);
  } else if (agility < 48) {
    moveSpeed = 31 + 0 * Math.abs(agility - 47);
  } else if (agility < 49) {
    moveSpeed = 31 + 1 * Math.abs(agility - 48);
  } else if (agility < 50) {
    moveSpeed = 32 + 0 * Math.abs(agility - 49);
  } else if (agility < 51) {
    moveSpeed = 32 + 1 * Math.abs(agility - 50);
  } else if (agility < 52) {
    moveSpeed = 33 + 0 * Math.abs(agility - 51);
  } else if (agility < 53) {
    moveSpeed = 33 + 1 * Math.abs(agility - 52);
  } else if (agility < 54) {
    moveSpeed = 34 + 0 * Math.abs(agility - 53);
  } else if (agility < 55) {
    moveSpeed = 34 + 1 * Math.abs(agility - 54);
  } else if (agility < 56) {
    moveSpeed = 35 + 0 * Math.abs(agility - 55);
  } else if (agility < 57) {
    moveSpeed = 35 + 1 * Math.abs(agility - 56);
  } else if (agility < 58) {
    moveSpeed = 36 + 0 * Math.abs(agility - 57);
  } else if (agility < 59) {
    moveSpeed = 36 + 1 * Math.abs(agility - 58);
  } else if (agility < 60) {
    moveSpeed = 37 + 0 * Math.abs(agility - 59);
  } else if (agility < 61) {
    moveSpeed = 37 + 1 * Math.abs(agility - 60);
  } else if (agility < 62) {
    moveSpeed = 38 + 0 * Math.abs(agility - 61);
  } else if (agility < 63) {
    moveSpeed = 38 + 1 * Math.abs(agility - 62);
  } else if (agility < 64) {
    moveSpeed = 39 + 0 * Math.abs(agility - 63);
  } else if (agility < 65) {
    moveSpeed = 39 + 1 * Math.abs(agility - 64);
  } else if (agility < 67) {
    moveSpeed = 40 + 0 * Math.abs(agility - 65);
  } else if (agility < 68) {
    moveSpeed = 40 + 1 * Math.abs(agility - 67);
  } else if (agility < 70) {
    moveSpeed = 41 + 0 * Math.abs(agility - 68);
  } else if (agility < 71) {
    moveSpeed = 41 + 1 * Math.abs(agility - 70);
  } else if (agility < 73) {
    moveSpeed = 42 + 0 * Math.abs(agility - 71);
  } else if (agility < 74) {
    moveSpeed = 42 + 1 * Math.abs(agility - 73);
  } else if (agility < 76) {
    moveSpeed = 43 + 0 * Math.abs(agility - 74);
  } else if (agility < 77) {
    moveSpeed = 43 + 1 * Math.abs(agility - 76);
  } else if (agility < 79) {
    moveSpeed = 44 + 0 * Math.abs(agility - 77);
  } else if (agility < 80) {
    moveSpeed = 44 + 1 * Math.abs(agility - 79);
  } else if (agility < 82) {
    moveSpeed = 45 + 0 * Math.abs(agility - 80);
  } else if (agility < 83) {
    moveSpeed = 45 + 1 * Math.abs(agility - 82);
  } else if (agility < 85) {
    moveSpeed = 46 + 0 * Math.abs(agility - 83);
  } else if (agility < 86) {
    moveSpeed = 46 + 1 * Math.abs(agility - 85);
  } else if (agility < 88) {
    moveSpeed = 47 + 0 * Math.abs(agility - 86);
  } else if (agility < 89) {
    moveSpeed = 47 + 1 * Math.abs(agility - 88);
  } else if (agility < 91) {
    moveSpeed = 48 + 0 * Math.abs(agility - 89);
  } else if (agility < 92) {
    moveSpeed = 48 + 1 * Math.abs(agility - 91);
  } else if (agility < 94) {
    moveSpeed = 49 + 0 * Math.abs(agility - 92);
  } else if (agility < 95) {
    moveSpeed = 49 + 1 * Math.abs(agility - 94);
  } else if (agility < 97) {
    moveSpeed = 50 + 0 * Math.abs(agility - 95);
  } else if (agility < 98) {
    moveSpeed = 50 + 1 * Math.abs(agility - 97);
  } else if (agility < 100) {
    moveSpeed = 51 + 0 * Math.abs(agility - 98);
  }

  return moveSpeed + 300;
}

export function calculateManualDexterity(dexterity) {
  let manualDexterity = 0;

  if (dexterity < 15) {
    manualDexterity = -0.15 + 0.01 * Math.abs(dexterity - 0);
  } else if (dexterity < 23) {
    manualDexterity = 0 + 0.03 * Math.abs(dexterity - 15);
  } else if (dexterity < 31) {
    manualDexterity = 0.24 + 0.02 * Math.abs(dexterity - 23);
  } else if (dexterity < 37) {
    manualDexterity = 0.4 + 0.01 * Math.abs(dexterity - 31);
  } else if (dexterity < 45) {
    manualDexterity = 0.46 + 0.005 * Math.abs(dexterity - 37);
  } else if (dexterity < 95) {
    manualDexterity = 0.5 + 0.001 * Math.abs(dexterity - 45);
  } else if (dexterity < 100) {
    manualDexterity = 0.55 + 0 * Math.abs(dexterity - 95);
  }

  return (manualDexterity * 100).toFixed(2);
}

export function calculateMagicResistance(will) {
  let magicResistance = 0;

  if (will < 5) {
    magicResistance = -20 + 4 * Math.abs(will - 0);
  } else if (will < 35) {
    magicResistance = 0 + 3 * Math.abs(will - 5);
  } else if (will < 55) {
    magicResistance = 90 + 2 * Math.abs(will - 35);
  } else if (will < 66) {
    magicResistance = 130 + 1 * Math.abs(will - 55);
  } else if (will < 67) {
    magicResistance = 141 + 0 * Math.abs(will - 66);
  } else if (will < 68) {
    magicResistance = 141 + 1 * Math.abs(will - 67);
  } else if (will < 69) {
    magicResistance = 142 + 0 * Math.abs(will - 68);
  } else if (will < 70) {
    magicResistance = 142 + 1 * Math.abs(will - 69);
  } else if (will < 71) {
    magicResistance = 143 + 0 * Math.abs(will - 70);
  } else if (will < 72) {
    magicResistance = 143 + 1 * Math.abs(will - 71);
  } else if (will < 73) {
    magicResistance = 144 + 0 * Math.abs(will - 72);
  } else if (will < 74) {
    magicResistance = 144 + 1 * Math.abs(will - 73);
  } else if (will < 75) {
    magicResistance = 145 + 0 * Math.abs(will - 74);
  } else if (will < 76) {
    magicResistance = 145 + 1 * Math.abs(will - 75);
  } else if (will < 77) {
    magicResistance = 146 + 0 * Math.abs(will - 76);
  } else if (will < 78) {
    magicResistance = 146 + 1 * Math.abs(will - 77);
  } else if (will < 79) {
    magicResistance = 147 + 0 * Math.abs(will - 78);
  } else if (will < 80) {
    magicResistance = 147 + 1 * Math.abs(will - 79);
  } else if (will < 82) {
    magicResistance = 148 + 0 * Math.abs(will - 80);
  } else if (will < 83) {
    magicResistance = 148 + 1 * Math.abs(will - 82);
  } else if (will < 84) {
    magicResistance = 149 + 0 * Math.abs(will - 83);
  } else if (will < 85) {
    magicResistance = 149 + 1 * Math.abs(will - 84);
  } else if (will < 86) {
    magicResistance = 150 + 0 * Math.abs(will - 85);
  } else if (will < 87) {
    magicResistance = 150 + 1 * Math.abs(will - 86);
  } else if (will < 88) {
    magicResistance = 151 + 0 * Math.abs(will - 87);
  } else if (will < 89) {
    magicResistance = 151 + 1 * Math.abs(will - 88);
  } else if (will < 90) {
    magicResistance = 152 + 0 * Math.abs(will - 89);
  } else if (will < 91) {
    magicResistance = 152 + 1 * Math.abs(will - 90);
  } else if (will < 92) {
    magicResistance = 153 + -1 * Math.abs(will - 91);
  } else if (will < 94) {
    magicResistance = 152 + 1 * Math.abs(will - 92);
  } else if (will < 95) {
    magicResistance = 154 + 0 * Math.abs(will - 94);
  } else if (will < 96) {
    magicResistance = 154 + 1 * Math.abs(will - 95);
  } else if (will < 97) {
    magicResistance = 155 + 0 * Math.abs(will - 96);
  } else if (will < 98) {
    magicResistance = 155 + 1 * Math.abs(will - 97);
  } else if (will < 99) {
    magicResistance = 156 + 0 * Math.abs(will - 98);
  } else if (will < 100) {
    magicResistance = 156 + 1 * Math.abs(will - 99);
  }

  return magicResistance;
}

export function calculateMagicalDamageReduction(magicResistance) {
  let magicalDamageReduction = 0;

  if (magicResistance < -15) {
    magicalDamageReduction = -5.95 + 0.02 * Math.abs(magicResistance + 300);
  } else if (magicResistance < 10) {
    magicalDamageReduction = -0.25 + 0.01 * Math.abs(magicResistance + 15);
  } else if (magicResistance < 19) {
    magicalDamageReduction = 0 + 0.005 * Math.abs(magicResistance - 10);
  } else if (magicResistance < 30) {
    magicalDamageReduction = 0.045 + 0.004 * Math.abs(magicResistance - 19);
  } else if (magicResistance < 40) {
    magicalDamageReduction = 0.089 + 0.003 * Math.abs(magicResistance - 30);
  } else if (magicResistance < 50) {
    magicalDamageReduction = 0.119 + 0.002 * Math.abs(magicResistance - 40);
  } else if (magicResistance < 100) {
    magicalDamageReduction = 0.139 + 0.001 * Math.abs(magicResistance - 50);
  } else if (magicResistance < 150) {
    magicalDamageReduction = 0.189 + 0.002 * Math.abs(magicResistance - 100);
  } else if (magicResistance < 250) {
    magicalDamageReduction = 0.289 + 0.003 * Math.abs(magicResistance - 150);
  } else if (magicResistance < 350) {
    magicalDamageReduction = 0.589 + 0.002 * Math.abs(magicResistance - 250);
  } else if (magicResistance < 500) {
    magicalDamageReduction = 0.789 + 0.001 * Math.abs(magicResistance - 350);
  }
  return (magicalDamageReduction * 100).toFixed(2);
}

export function calculateDebuffDuration(will) {
  let debuffDuration = 0;

  if (will < 1) {
    debuffDuration = 4 - 1.667 * Math.abs(will - 0);
  } else if (will < 2) {
    debuffDuration = 2.333 - 0.833 * Math.abs(will - 1);
  } else if (will < 3) {
    debuffDuration = 1.5 - 0.5 * Math.abs(will - 2);
  } else if (will < 4) {
    debuffDuration = 1 - 0.333 * Math.abs(will - 3);
  } else if (will < 5) {
    debuffDuration = 0.667 - 0.238 * Math.abs(will - 4);
  } else if (will < 6) {
    debuffDuration = 0.429 - 0.096 * Math.abs(will - 5);
  } else if (will < 7) {
    debuffDuration = 0.333 - 0.083 * Math.abs(will - 6);
  } else if (will < 8) {
    debuffDuration = 0.25 - 0.045 * Math.abs(will - 7);
  } else if (will < 9) {
    debuffDuration = 0.205 - 0.042 * Math.abs(will - 8);
  } else if (will < 10) {
    debuffDuration = 0.163 - 0.039 * Math.abs(will - 9);
  } else if (will < 11) {
    debuffDuration = 0.124 - 0.037 * Math.abs(will - 10);
  } else if (will < 12) {
    debuffDuration = 0.087 - 0.023 * Math.abs(will - 11);
  } else if (will < 14) {
    debuffDuration = 0.064 - 0.022 * Math.abs(will - 12);
  } else if (will < 15) {
    debuffDuration = 0.02 - 0.02 * Math.abs(will - 14);
  } else if (will < 17) {
    debuffDuration = 0 - 0.01 * Math.abs(will - 15);
  } else if (will < 19) {
    debuffDuration = -0.02 - 0.009 * Math.abs(will - 17);
  } else if (will < 20) {
    debuffDuration = -0.038 - 0.01 * Math.abs(will - 19);
  } else if (will < 21) {
    debuffDuration = -0.048 - 0.009 * Math.abs(will - 20);
  } else if (will < 22) {
    debuffDuration = -0.057 - 0.008 * Math.abs(will - 21);
  } else if (will < 24) {
    debuffDuration = -0.065 - 0.009 * Math.abs(will - 22);
  } else if (will < 29) {
    debuffDuration = -0.083 - 0.008 * Math.abs(will - 24);
  } else if (will < 30) {
    debuffDuration = -0.123 - 0.007 * Math.abs(will - 29);
  } else if (will < 31) {
    debuffDuration = -0.13 - 0.008 * Math.abs(will - 30);
  } else if (will < 32) {
    debuffDuration = -0.138 - 0.007 * Math.abs(will - 31);
  } else if (will < 33) {
    debuffDuration = -0.145 - 0.008 * Math.abs(will - 32);
  } else if (will < 36) {
    debuffDuration = -0.153 - 0.007 * Math.abs(will - 33);
  } else if (will < 37) {
    debuffDuration = -0.174 - 0.006 * Math.abs(will - 36);
  } else if (will < 39) {
    debuffDuration = -0.18 - 0.007 * Math.abs(will - 37);
  } else if (will < 41) {
    debuffDuration = -0.194 - 0.006 * Math.abs(will - 39);
  } else if (will < 42) {
    debuffDuration = -0.206 - 0.007 * Math.abs(will - 41);
  } else if (will < 46) {
    debuffDuration = -0.213 - 0.006 * Math.abs(will - 42);
  } else if (will < 47) {
    debuffDuration = -0.237 - 0.005 * Math.abs(will - 46);
  } else if (will < 49) {
    debuffDuration = -0.242 - 0.006 * Math.abs(will - 47);
  } else if (will < 50) {
    debuffDuration = -0.254 - 0.005 * Math.abs(will - 49);
  } else if (will < 52) {
    debuffDuration = -0.259 - 0.003 * Math.abs(will - 50);
  } else if (will < 53) {
    debuffDuration = -0.265 - 0.002 * Math.abs(will - 52);
  } else if (will < 55) {
    debuffDuration = -0.267 - 0.003 * Math.abs(will - 53);
  } else if (will < 56) {
    debuffDuration = -0.273 - 0.002 * Math.abs(will - 55);
  } else if (will < 58) {
    debuffDuration = -0.275 - 0.003 * Math.abs(will - 56);
  } else if (will < 59) {
    debuffDuration = -0.281 - 0.002 * Math.abs(will - 58);
  } else if (will < 60) {
    debuffDuration = -0.283 - 0.003 * Math.abs(will - 59);
  } else if (will < 61) {
    debuffDuration = -0.286 - 0.002 * Math.abs(will - 60);
  } else if (will < 62) {
    debuffDuration = -0.288 - 0.003 * Math.abs(will - 61);
  } else if (will < 63) {
    debuffDuration = -0.291 - 0.002 * Math.abs(will - 62);
  } else if (will < 64) {
    debuffDuration = -0.293 - 0.003 * Math.abs(will - 63);
  } else if (will < 65) {
    debuffDuration = -0.296 - 0.002 * Math.abs(will - 64);
  } else if (will < 66) {
    debuffDuration = -0.298 - 0.003 * Math.abs(will - 65);
  } else if (will < 67) {
    debuffDuration = -0.301 - 0.002 * Math.abs(will - 66);
  } else if (will < 68) {
    debuffDuration = -0.303 - 0.003 * Math.abs(will - 67);
  } else if (will < 70) {
    debuffDuration = -0.306 - 0.002 * Math.abs(will - 68);
  } else if (will < 71) {
    debuffDuration = -0.31 - 0.003 * Math.abs(will - 70);
  } else if (will < 73) {
    debuffDuration = -0.313 - 0.002 * Math.abs(will - 71);
  } else if (will < 74) {
    debuffDuration = -0.317 - 0.003 * Math.abs(will - 73);
  } else if (will < 76) {
    debuffDuration = -0.32 - 0.002 * Math.abs(will - 74);
  } else if (will < 77) {
    debuffDuration = -0.324 - 0.003 * Math.abs(will - 76);
  } else if (will < 80) {
    debuffDuration = -0.327 - 0.002 * Math.abs(will - 77);
  } else if (will < 81) {
    debuffDuration = -0.333 - 0.003 * Math.abs(will - 80);
  } else if (will < 86) {
    debuffDuration = -0.336 - 0.002 * Math.abs(will - 81);
  } else if (will < 87) {
    debuffDuration = -0.346 - 0.003 * Math.abs(will - 86);
  } else if (will < 100) {
    debuffDuration = -0.349 - 0.002 * Math.abs(will - 87);
  }

  return (debuffDuration * 100).toFixed(2);
}

export function calculateBuffDuration(will) {
  let buffDuration = 0;

  if (will < 5) {
    buffDuration = -0.8 + 0.1 * Math.abs(will - 0);
  } else if (will < 7) {
    buffDuration = -0.3 + 0.05 * Math.abs(will - 5);
  } else if (will < 11) {
    buffDuration = -0.2 + 0.03 * Math.abs(will - 7);
  } else if (will < 15) {
    buffDuration = -0.08 + 0.02 * Math.abs(will - 11);
  } else if (will < 50) {
    buffDuration = 0 + 0.01 * Math.abs(will - 15);
  } else if (will < 100) {
    buffDuration = 0.35 + 0.005 * Math.abs(will - 50);
  }

  return buffDuration;
}

export function calculateMagicalInteractionSpeed(will) {
  // Calculate Magical Interaction Speed
  let magicalInteractionSpeed = 0;

  if (will < 15) {
    magicalInteractionSpeed = -0.75 + 0.05 * Math.abs(will - 0);
  } else if (will < 25) {
    magicalInteractionSpeed = 0 + 0.07 * Math.abs(will - 15);
  } else if (will < 35) {
    magicalInteractionSpeed = 0.7 + 0.05 * Math.abs(will - 25);
  } else if (will < 84) {
    magicalInteractionSpeed = 1.2 + 0.02 * Math.abs(will - 35);
  } else if (will < 85) {
    magicalInteractionSpeed = 2.18 + 0.01 * Math.abs(will - 84);
  } else if (will < 86) {
    magicalInteractionSpeed = 2.19 + 0.03 * Math.abs(will - 85);
  } else if (will < 100) {
    magicalInteractionSpeed = 2.22 + 0.02 * Math.abs(will - 86);
  }

  return (magicalInteractionSpeed * 100).toFixed(2);
}

export function calculateSpellCastingSpeed(knowledge) {
  let spellCastingSpeed = 0;

  if (knowledge < 1) {
    spellCastingSpeed = -0.93 + 0 * Math.abs(knowledge - 0);
  } else if (knowledge < 19) {
    spellCastingSpeed = -0.93 + 0.05 * Math.abs(knowledge - 1);
  } else if (knowledge < 25) {
    spellCastingSpeed = -0.03 + 0.03 * Math.abs(knowledge - 19);
  } else if (knowledge < 55) {
    spellCastingSpeed = 0.15 + 0.025 * Math.abs(knowledge - 25);
  } else if (knowledge < 85) {
    spellCastingSpeed = 0.9 + 0.02 * Math.abs(knowledge - 55);
  } else if (knowledge < 100) {
    spellCastingSpeed = 1.5 + 0.015 * Math.abs(knowledge - 85);
  }
  console.log(spellCastingSpeed);
  return spellCastingSpeed.toFixed(2);
}

export function calculatePhysicalDamageReduction(armorRating) {
  let physicalDamageReduction = 0;

  if (armorRating < -3) {
    physicalDamageReduction = -6.19 + 0.02 * Math.abs(armorRating - -300);
  } else if (armorRating < 22) {
    physicalDamageReduction = -0.25 + 0.01 * Math.abs(armorRating - -3);
  } else if (armorRating < 31) {
    physicalDamageReduction = 0 + 0.005 * Math.abs(armorRating - 22);
  } else if (armorRating < 42) {
    physicalDamageReduction = 0.045 + 0.004 * Math.abs(armorRating - 31);
  } else if (armorRating < 52) {
    physicalDamageReduction = 0.089 + 0.003 * Math.abs(armorRating - 42);
  } else if (armorRating < 62) {
    physicalDamageReduction = 0.119 + 0.002 * Math.abs(armorRating - 52);
  } else if (armorRating < 112) {
    physicalDamageReduction = 0.139 + 0.001 * Math.abs(armorRating - 62);
  } else if (armorRating < 175) {
    physicalDamageReduction = 0.189 + 0.002 * Math.abs(armorRating - 112);
  } else if (armorRating < 230) {
    physicalDamageReduction = 0.315 + 0.003 * Math.abs(armorRating - 175);
  } else if (armorRating < 317) {
    physicalDamageReduction = 0.48 + 0.002 * Math.abs(armorRating - 230);
  } else if (armorRating < 353) {
    physicalDamageReduction = 0.654 + 0.001 * Math.abs(armorRating - 317);
  } else if (armorRating < 368) {
    physicalDamageReduction = 0.69 + 0.001 * Math.abs(armorRating - 353);
  } else if (armorRating < 369) {
    physicalDamageReduction = 0.698 + 0 * Math.abs(armorRating - 368);
  } else if (armorRating < 370) {
    physicalDamageReduction = 0.698 + 0.001 * Math.abs(armorRating - 369);
  } else if (armorRating < 428) {
    physicalDamageReduction = 0.699 + 0.001 * Math.abs(armorRating - 370);
  } else if (armorRating < 429) {
    physicalDamageReduction = 0.728 - 0.001 * Math.abs(armorRating - 428);
  } else if (armorRating < 450) {
    physicalDamageReduction = 0.727 + 0 * Math.abs(armorRating - 429);
  } else if (armorRating < 500) {
    physicalDamageReduction = 0.732 + 0 * Math.abs(armorRating - 450);
  }

  return (physicalDamageReduction * 100).toFixed(2);
}

export function calculateRegularInteractionSpeed(sum) {
  let regularInteractionSpeed = 0;

  if (sum < 5) {
    regularInteractionSpeed = -0.55 + 0.05 * Math.abs(sum - 0);
  } else if (sum < 15) {
    regularInteractionSpeed = -0.3 + 0.03 * Math.abs(sum - 5);
  } else if (sum < 25) {
    regularInteractionSpeed = 0 + 0.07 * Math.abs(sum - 15);
  } else if (sum < 35) {
    regularInteractionSpeed = 0.7 + 0.05 * Math.abs(sum - 25);
  } else if (sum < 84) {
    regularInteractionSpeed = 1.2 + 0.02 * Math.abs(sum - 35);
  } else if (sum < 85) {
    regularInteractionSpeed = 2.18 + 0.01 * Math.abs(sum - 84);
  } else if (sum < 86) {
    regularInteractionSpeed = 2.19 + 0.03 * Math.abs(sum - 85);
  } else if (sum < 100) {
    regularInteractionSpeed = 2.22 + 0.02 * Math.abs(sum - 86);
  }

  return (regularInteractionSpeed * 100).toFixed(2);
}

export function calculateMemoryCapacity(knowledge) {
  let memoryCapacity = 0;

  if (knowledge < 6) {
    memoryCapacity = 0 + 0 * Math.abs(knowledge - 0);
  } else if (knowledge < 100) {
    memoryCapacity = 0 + 1 * Math.abs(knowledge - 6);
  }

  return memoryCapacity;
}

export function calculateMemoryRecovery(knowledge) {
  let memoryRecovery = 0;

  if (knowledge < 28) {
    memoryRecovery = 0.43 + 0.015 * Math.abs(knowledge - 0);
  } else if (knowledge < 35) {
    memoryRecovery = 0.85 + 0.05 * Math.abs(knowledge - 28);
  } else if (knowledge < 84) {
    memoryRecovery = 1.2 + 0.02 * Math.abs(knowledge - 35);
  } else if (knowledge < 85) {
    memoryRecovery = 2.18 + 0.01 * Math.abs(knowledge - 84);
  } else if (knowledge < 86) {
    memoryRecovery = 2.19 + 0.03 * Math.abs(knowledge - 85);
  } else if (knowledge < 100) {
    memoryRecovery = 2.22 + 0.02 * Math.abs(knowledge - 86);
  }

  return memoryRecovery.toFixed(2);
}

export function calculateCoolDownReduction(resourcefulness) {
  let cooldownReduction = 0;

  if (resourcefulness < 20) {
    cooldownReduction = -0.3 + 0.02 * Math.abs(resourcefulness - 0);
  } else if (resourcefulness < 50) {
    cooldownReduction = 0.1 + 0.01 * Math.abs(resourcefulness - 20);
  } else if (resourcefulness < 100) {
    cooldownReduction = 0.4 + 0.005 * Math.abs(resourcefulness - 50);
  }

  return cooldownReduction.toFixed(2);
}

export function calculateResourcefulness(resourcefulness) {
  let persuasiveness = 0;

  if (resourcefulness < 35) {
    persuasiveness = 0 + 1 * Math.abs(resourcefulness - 0);
  } else if (resourcefulness < 71) {
    persuasiveness = 35 + 0.5 * Math.abs(resourcefulness - 35);
  } else if (resourcefulness < 99) {
    persuasiveness = 53 + 0.25 * Math.abs(resourcefulness - 71);
  } else if (resourcefulness < 100) {
    persuasiveness = 60 + 0 * Math.abs(resourcefulness - 99);
  }

  return persuasiveness.toFixed(2);
}
export function calculateMaxHealth(sum) {
  let maxHealth = 0;

  if (sum < 10) {
    maxHealth = 60 + 3 * Math.abs(sum - 0);
  } else if (sum < 50) {
    maxHealth = 90 + 2 * Math.abs(sum - 10);
  } else if (sum < 75) {
    maxHealth = 170 + 1 * Math.abs(sum - 50);
  } else if (sum < 100) {
    maxHealth = 195 + 0.5 * Math.abs(sum - 75);
  }

  return maxHealth.toFixed(2);
}

export function calculateActionSpeed(sum) {
  let actionSpeed = 0;

  if (sum < 10) {
    actionSpeed = -0.38 + 0.03 * Math.abs(sum - 0);
  } else if (sum < 13) {
    actionSpeed = -0.08 + 0.02 * Math.abs(sum - 10);
  } else if (sum < 25) {
    actionSpeed = -0.02 + 0.01 * Math.abs(sum - 13);
  } else if (sum < 41) {
    actionSpeed = 0.1 + 0.015 * Math.abs(sum - 25);
  } else if (sum < 50) {
    actionSpeed = 0.34 + 0.01 * Math.abs(sum - 41);
  } else if (sum < 100) {
    actionSpeed = 0.43 + 0.005 * Math.abs(sum - 50);
  }

  return actionSpeed.toFixed(2);
}

export function calculateMagicalPower(will) {
  let magicalPower = 0;

  if (will < 100) {
    magicalPower = 0 + 1 * Math.abs(will - 0);
  }

  return magicalPower;
}

export function calculatePhysicalPower(strength) {
  if (strength < 100) {
    return 0 + 1 * Math.abs(strength - 0);
  }
}
