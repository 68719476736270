import { Typography, Form, InputNumber, Select, Checkbox, Tooltip } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import CalculatorCard from './calculatorCard';
import songData from '../../data/songData.json';
import characterData from '../../data/characterStats.json';
import { QuestionCircleOutlined } from '@ant-design/icons';
import ReactGA from 'react-ga4';

// eslint-disable-next-line react/prop-types
const QuestionTooltip = (text) => {
  return (
    <Tooltip title={text}>
      <QuestionCircleOutlined style={{ color: '#1890ff' }} />
    </Tooltip>
  );
};
const { Paragraph } = Typography;

function BuffDurationCalculator() {
  const defaultBuffDurationFromGear = 0;
  const [selectedCharacter, setSelectedCharacter] = useState(
    characterData.Classes.find((character) => character.Name === 'Bard'),
  );

  const [will, setWill] = useState(characterData.Classes.Will);
  const [buffDurBonus, setBuffDurBonus] = useState(0); // Initialize with 0
  const [buffDurFromGear, setBuffDurFromGear] = useState(
    defaultBuffDurationFromGear,
  );
  const [selectedSong, setSelectedSong] = useState(songData.songs[0]);
  const [persuasiveness, setPersuasiveness] = useState(0); // Initialize with 0
  const [useWill, setUseWill] = useState(false);
  const [prevCharacter, setPrevCharacter] = useState(selectedCharacter);

  const calculatePersuasiveness = (resourcefulness) => {
    if (resourcefulness < 35) {
      return 0 + 1 * Math.abs(resourcefulness - 0);
    } else if (resourcefulness < 71) {
      return 35 + 0.5 * Math.abs(resourcefulness - 35);
    } else if (resourcefulness < 99) {
      return 53 + 0.25 * Math.abs(resourcefulness - 71);
    } else if (resourcefulness < 100) {
      return 60 + 0 * Math.abs(resourcefulness - 99);
    } else {
      // Handle resourcefulness values outside the defined ranges
      return 0;
    }
  };

  const calculateWillBuffDuration = useCallback((will) => {
    const willBuffScaling = [
      { range: { min: 1, max: 5 }, rate: 10 },
      { range: { min: 6, max: 7 }, rate: 5 },
      { range: { min: 8, max: 11 }, rate: 3 },
      { range: { min: 12, max: 15 }, rate: 2 },
      { range: { min: 16, max: 50 }, rate: 1 },
      { range: { min: 51, max: 100 }, rate: 0.5 },
    ];

    let buffDuration = -80; // Initialize buffDuration to -80%

    for (let i = 1; i <= will; i++) {
      for (const scaling of willBuffScaling) {
        const { min, max } = scaling.range;
        if (i >= min && i <= max) {
          buffDuration += scaling.rate;
        }
      }
    }

    return buffDuration;
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/buffcalculation',
      title: 'Buff Duration Calc',
    });
  }, []);

  useEffect(() => {
    setBuffDurBonus(calculateWillBuffDuration(selectedCharacter.Will));
    setPersuasiveness(
      calculatePersuasiveness(selectedCharacter.Resourcefulness),
    );
  }, [
    calculateWillBuffDuration,
    selectedCharacter.Resourcefulness,
    selectedCharacter.Will,
  ]);

  useEffect(() => {
    if (selectedCharacter !== prevCharacter) {
      const newWill = selectedCharacter.Will;
      setWill(newWill);
      setBuffDurBonus(calculateWillBuffDuration(newWill));
      setPersuasiveness(
        calculatePersuasiveness(selectedCharacter.Resourcefulness),
      );
      setPrevCharacter(selectedCharacter);
    }
  }, [selectedCharacter, prevCharacter, calculateWillBuffDuration]);

  const handleSongChange = (value) => {
    setSelectedSong(songData.songs.find((song) => song.name === value));
  };

  const handleCharacterChange = (value) => {
    const selected = characterData.Classes.find(
      (character) => character.Name === value,
    );
    setSelectedCharacter(selected);
  };

  const handlePersuasivenessChange = (value) => {
    setPersuasiveness(value);
  };

  const onWillChange = (value) => {
    setWill(value);
  };

  const onFlatBuffDurationChange = (value) => {
    setBuffDurBonus(value);
  };

  const onFlatBuffDurationFromGearChange = (value) => {
    setBuffDurFromGear(value);
  };

  const calculateSongDuration = () => {
    const baseDuration =
      selectedSong.scaling * calculatePersuasiveness(persuasiveness);
    return baseDuration;
  };

  const calcSongDuration = () => {
    if (useWill) {
      return (
        calculateSongDuration() +
        calculateWillBuffDuration(will) +
        buffDurFromGear
      );
    } else {
      return calculateSongDuration() + buffDurBonus;
    }
  };
  const calcTotalBuffDur = () => {
    if (useWill) {
      return calculateWillBuffDuration(will) + buffDurFromGear;
    } else {
      return buffDurBonus;
    }
  };

  const BuffDurInputComponents = [
    <Form.Item label="Character">
      <Select
        defaultValue={selectedCharacter.Name}
        onChange={handleCharacterChange}
      >
        {characterData.Classes.map((character) => (
          <Select.Option key={character.Name} value={character.Name}>
            {character.Name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,
    <Form.Item label="Use Will In Calculation">
      <Checkbox
        checked={useWill}
        onChange={(e) => setUseWill(e.target.checked)}
        className="button-margin"
      />
      {QuestionTooltip(
        'Will modifies the buff duration, calculating it seperately can help decide which stat will have a greater effect on your buff duration',
      )}
    </Form.Item>,
    useWill && (
      <Form.Item label="Will">
        <InputNumber min={0} max={100} value={will} onChange={onWillChange} />
      </Form.Item>
    ),
    <Form.Item
      label="Song"
      style={{ display: selectedCharacter.Name === 'Bard' ? 'block' : 'none' }}
    >
      <Select defaultValue={selectedSong.Name} onChange={handleSongChange}>
        {songData.songs.map((song) => (
          <Select.Option key={song.name} value={song.name}>
            {song.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>,

    <Form.Item
      label="Persuasiveness"
      style={selectedCharacter.Name === 'Bard' ? {} : { display: 'none' }}
    >
      <InputNumber
        min={0}
        max={100}
        value={persuasiveness}
        onChange={handlePersuasivenessChange}
      />
    </Form.Item>,
    useWill ? (
      <Form.Item label="Buff Duration from Gear">
        <InputNumber
          min={0}
          max={100}
          value={buffDurFromGear}
          onChange={onFlatBuffDurationFromGearChange}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
        />
      </Form.Item>
    ) : (
      <Form.Item label="Buff Duration Bonus">
        <InputNumber
          min={-11}
          max={100}
          value={buffDurBonus}
          onChange={onFlatBuffDurationChange}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
        />
      </Form.Item>
    ),
  ];

  // Output components
  const bardBuffDurOutputObjects = [
    {
      outputLabel: 'Buff Duration',
      value: calcTotalBuffDur().toFixed(1) + '%',
    },
    {
      outputLabel: 'Song Duration',
      value: calcSongDuration().toFixed(1) + ' Seconds',
    },
  ];

  const allBuffDurOutputObjects = [
    {
      outputLabel: 'Buff Duration',
      value: calcTotalBuffDur().toFixed(1) + '%',
    },
  ];

  const buffDurDescription = [
    <Paragraph>
      Buff duration is calculated by adding buff duration from will and flat
      buff duration bonuses. Song duration is calculated based on the song
      duration, persuasiveness, buff duration bonus. All of the information to
      input can be found in the details page when in your stash. (Bottom left to
      open details){' '}
    </Paragraph>,
  ];

  // Render
  return (
    <CalculatorCard
      title="Buff Duration Calculator"
      formInputComponentList={BuffDurInputComponents}
      descComponents={buffDurDescription}
      outputObjects={
        selectedCharacter.Name === 'Bard'
          ? bardBuffDurOutputObjects
          : allBuffDurOutputObjects
      }
    />
  );
}

export default BuffDurationCalculator;
