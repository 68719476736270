import React, { useState } from 'react';
import { Popover, Select, InputNumber } from 'antd';
import { getRarityColor } from '../../shared/text.js';
import axios from 'axios';
import PropTypes from 'prop-types';
const rarityTypes = {
  '0001': 'Junk',
  1001: 'Poor',
  2001: 'Common',
  3001: 'Uncommon',
  4001: 'Rare',
  5001: 'Epic',
  6001: 'Legendary',
  7001: 'Unique',
};
const { Option } = Select;
const importAll = (r) => {
  let images = {};
  r.keys().map((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
};
const images = importAll(
  require.context('../../img/itemIcons', false, /\.(png|jpe?g|svg)$/),
);

const FileSelect = React.memo(
  ({
    folder,
    style,
    setAllPrimaryProperties,
    allPrimaryProperties,
    selectedFiles,
    setSelectedFiles,
  }) => {
    const [fileContents, setFileContents] = useState({});
    const [selectedIcons, setSelectedIcons] = useState({});
    const [selectedValue, setSelectedValue] = useState({});
    const [secondaryInputValues, setSecondaryInputValues] = useState({});
    const [focusedFolder, setFocusedFolder] = useState(null);

    const [visible, setVisible] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);

    const [previousSecondaryProperties, setPreviousSecondaryProperties] =
      useState({});
    const [selectedSecondaryProperties, setSelectedSecondaryProperties] =
      useState({});

    const handleSecondaryPropertyChange = (folder, index, newValue) => {
      const defaultValue = fileContents[folder]?.SecondaryProperties.find(
        (property) => property.PropertyTypeId === newValue,
      )?.maxValue;

      setAllPrimaryProperties((prevProperties) => {
        const newProperties = { ...prevProperties };

        const previousProperty = previousSecondaryProperties[folder]?.[index];
        if (previousProperty) {
          delete newProperties[folder][previousProperty];
        }

        newProperties[folder] = {
          ...newProperties[folder],
          [newValue]: defaultValue,
        };

        return newProperties;
      });

      setPreviousSecondaryProperties((prevProperties) => ({
        ...prevProperties,
        [folder]: {
          ...prevProperties[folder],
          [index]: newValue,
        },
      }));

      setSelectedSecondaryProperties((prevProperties) => ({
        ...prevProperties,
        [folder]: {
          ...prevProperties[folder],
          [index]: newValue,
        },
      }));

      setSecondaryInputValues((prevValues) => ({
        ...prevValues,
        [folder]: {
          ...prevValues[folder],
          [index]: defaultValue,
        },
      }));
    };

    const handleSecondaryInputChange = (folder, index, newValue) => {
      setAllPrimaryProperties((prevProperties) => ({
        ...prevProperties,
        [folder]: {
          ...prevProperties[folder],
          [selectedSecondaryProperties[folder][index]]: newValue,
          gearScore: fileContents[folder]?.GearScore,
        },
      }));
      setSecondaryInputValues((prevValues) => ({
        ...prevValues,
        [folder]: {
          ...prevValues[folder],
          [index]: newValue,
        },
      }));
    };

    const clearSelectedObject = () => {
      setSelectedIcons((prevIcons) => {
        const newIcons = { ...prevIcons };
        delete newIcons[folder];
        return newIcons;
      });

      setSelectedValue((prevValue) => {
        const newValue = { ...prevValue };
        delete newValue[folder];
        return newValue;
      });

      setAllPrimaryProperties((prevProperties) => {
        const newProperties = { ...prevProperties };
        delete newProperties[folder];
        return newProperties;
      });

      setSelectedSecondaryProperties((prevProperties) => {
        const newProperties = { ...prevProperties };
        delete newProperties[folder];
        return newProperties;
      });
    };
    const handleSelect = async (type, value) => {
      setFocusedFolder(type);
      setSelectedValue((prevState) => ({ ...prevState, [type]: value }));
      const sanitizedValue = value.replace(/-1|-2$/, '');
      try {
        const response = await axios.get(
          `/data/slots/${type}/${sanitizedValue}`,
        );

        setFileContents((prevFileContents) => {
          const newFileContents = {
            ...prevFileContents,
            [type]: response.data,
          };
          const newPrimaryProperties = {};
          for (const property of Object.values(
            newFileContents[type]?.PrimaryProperties || {},
          )) {
            newPrimaryProperties[property.PropertyTypeId] = property.maxValue;
          }

          setAllPrimaryProperties((prevProperties) => ({
            ...prevProperties,
            [type]: {
              ...newPrimaryProperties,
              gearScore: response.data.GearScore,
            },
          }));

          setSelectedSecondaryProperties((prevProperties) => ({
            ...prevProperties,
            [type]: {},
          }));

          return newFileContents;
        });

        let iconName = value
          .replace('Id_Item_', 'Item_Icon_')
          .replace('.json', '.png');

        if (!Object.keys(images).includes(iconName)) {
          iconName = iconName.replace(/_\d+\.png$/, '.png');
        }

        if (!Object.keys(images).includes(iconName)) {
          iconName = iconName.replace(/_\d{4}$/, '');
        }

        setSelectedIcons((prev) => ({ ...prev, [type]: iconName }));
      } catch (error) {
        console.error('Failed to load file contents:', error);
      }
    };

    const handleInputChange = (folder, propertyType, newValue) => {
      setAllPrimaryProperties((prevProperties) => {
        return {
          ...prevProperties,
          [folder]: {
            ...prevProperties[folder],
            [propertyType]: newValue,
          },
        };
      });
    };

    let height;
    switch (folder) {
      case 'Primary':
      case 'Primary2':
        height = '385%';
        break;
      case 'Secondary':
      case 'Secondary2':
        height = '150%';
        break;
      case 'Head':
      case 'Foot':
      case 'Hands':
        height = '200%';
        break;
      case 'Back':
        height = '180%';
        break;
      case 'Chest':
      case 'Legs':
        height = '375%';
        break;
      case 'Necklace':
      case 'Ring1':
      case 'Ring2':
        height = '95%';
        break;
      default:
        height = '50%';
    }
    let width;
    switch (folder) {
      case 'Primary':
      case 'Primary2':
        width = '55%';
        break;
      case 'Secondary':
      case 'Secondary2':
        width = '95%';
        break;

      case 'Back':
        width = '63%';
        break;
      case 'Head':
        width = '95%';
        break;
      case 'Foot':
      case 'Hands':
        width = '75%';
        break;
      case 'Chest':
      case 'Legs':
        width = '70%';
        break;
      case 'Necklace':
      case 'Ring1':
      case 'Ring2':
        width = '55%';
        break;
      default:
        width = '50%';
    }
    let marginLeft;
    switch (folder) {
      case 'Primary':
        marginLeft = '.5vw';
        break;
      case 'Primary2':
        marginLeft = '1.5vw';
        break;
      case 'Secondary':
        marginLeft = '-.8vw';
        break;
      case 'Secondary2':
        marginLeft = '-1vw';
        break;
      case 'Back':
        marginLeft = '2vw';
        break;
      case 'Foot':
        marginLeft = '1.2vw';
        break;
      case 'Hands':
        marginLeft = '-1vw';
        break;
      case 'Necklace':
        marginLeft = '1.5vw';
        break;
      case 'Ring1':
        marginLeft = '.6vw';
        break;
      case 'Ring2':
        marginLeft = '1.1vw';
        break;
      case 'Chest':
        marginLeft = '1.2vw';
        break;
      case 'Legs':
        marginLeft = '1.2vw';
        break;
      case 'Head':
        marginLeft = '0vw';
        break;

      default:
        marginLeft = '1vw';
    }

    let marginTop;
    switch (folder) {
      case 'Primary':
        marginTop = '-2vh';
        break;
      case 'Primary2':
        marginTop = '-2vh';
        break;
      case 'Secondary':
        marginTop = '8vh';
        break;
      case 'Secondary2':
        marginTop = '8vh';
        break;

      case 'Foot':
        marginTop = '1.5vh';
        break;
      case 'Hands':
        marginTop = '0vh';
        break;
      case 'Back':
        marginTop = '-1vh';
        break;
      case 'Head':
        marginTop = '0vh';
        break;
      case 'Chest':
        marginTop = '4.5vh';
        break;
      case 'Legs':
        marginTop = '1vh';
        break;
      case 'Necklace':
        marginTop = '0vh';
        break;
      case 'Ring1':
      case 'Ring2':
        marginTop = '.8vh';
        break;
      default:
        marginTop = '2vh';
    }

    return (
      <div
        style={{
          ...style,
          width: '8.7w',

          position: 'relative',
        }}
      >
        {!images[selectedIcons[folder]] || !imageLoaded ? <div /> : null}
        <Popover
          content={
            <>
              <button
                onClick={clearSelectedObject}
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',

                  zIndex: 2,
                }}
              >
                Clear
              </button>

              <Select
                showSearch
                style={{ width: '10vw' }}
                value={selectedValue[folder]}
                onChange={(value) => handleSelect(folder, value)}
              >
                {Object.keys(selectedFiles[folder] || {}).map((file, index) => (
                  <Option key={file} value={file} style={{ width: '10vw' }}>
                    {file
                      .replace('Id_Item_', '')
                      .split('_')[0]
                      .replace(/(?<=.)([A-Z])/g, ' $1') + ' '}
                    <span
                      style={{
                        color: getRarityColor(
                          file
                            .replace('Id_Item_', '')
                            .split('_')
                            .slice(-1)[0]
                            .replace('.json', ''),
                        ),
                      }}
                    >
                      (
                      {
                        rarityTypes[
                          file
                            .replace('Id_Item_', '')
                            .split('_')
                            .slice(-1)[0]
                            .replace('.json', '')
                        ]
                      }
                      )
                    </span>
                  </Option>
                ))}
              </Select>
              {fileContents[folder] && folder === focusedFolder && (
                <div>
                  <br></br>
                  Gear Score:
                  {allPrimaryProperties[folder]
                    ? ' ' + allPrimaryProperties[folder].gearScore
                    : null}
                  {Object.entries(
                    fileContents[folder]?.PrimaryProperties || {},
                  ).map(([key, value]) => (
                    <div key={key}>
                      {Object.entries(value)
                        .filter(
                          ([subKey]) =>
                            subKey !== 'minValue' && subKey !== 'maxValue',
                        )
                        .map(([subKey, subValue]) => (
                          <div key={subKey}>
                            <p>
                              {subValue
                                .split('_')[3]
                                .replace(/(?<=.)([A-Z])/g, ' $1')}{' '}
                              {allPrimaryProperties[folder]
                                ? allPrimaryProperties[folder][subValue]
                                : undefined}
                            </p>
                            {value.maxValue !== value.minValue && (
                              <InputNumber
                                min={value.minValue}
                                max={value.maxValue}
                                value={
                                  allPrimaryProperties[folder]
                                    ? allPrimaryProperties[folder][subValue]
                                    : undefined
                                }
                                onChange={(newValue) =>
                                  handleInputChange(folder, subValue, newValue)
                                }
                              />
                            )}
                          </div>
                        ))}
                    </div>
                  ))}
                  {Array.from(
                    {
                      length:
                        fileContents[folder]?.NumofSecondaryProperties || 0,
                    },
                    (_, i) => {
                      const selectedPropertyIds = Object.values(
                        selectedSecondaryProperties[folder] || {},
                      );
                      const availableProperties =
                        fileContents[folder]?.SecondaryProperties?.filter(
                          (property) => {
                            const isSelected = selectedPropertyIds.includes(
                              property.PropertyTypeId,
                            );
                            const isZeroRange =
                              property.minValue === 0 &&
                              property.maxValue === 0;
                            return !isSelected && !isZeroRange;
                          },
                        ) || [];

                      return (
                        <div key={i}>
                          <Select
                            showSearch
                            style={{ width: '10vw' }}
                            value={
                              selectedSecondaryProperties[folder]?.[i]
                                ?.split('_')[3]
                                .replace(/(?<=.)([A-Z])/g, ' $1') || undefined
                            }
                            onChange={(value) =>
                              handleSecondaryPropertyChange(folder, i, value)
                            }
                          >
                            {availableProperties.map((property, index) => (
                              <Option
                                key={property.PropertyTypeId}
                                value={property.PropertyTypeId}
                              >
                                {property.PropertyTypeId.split('_')[3].replace(
                                  /(?<=.)([A-Z])/g,
                                  ' $1',
                                )}
                              </Option>
                            ))}
                          </Select>
                          {selectedSecondaryProperties[folder]?.[i] && (
                            <div>
                              <InputNumber
                                min={
                                  fileContents[
                                    folder
                                  ]?.SecondaryProperties?.find(
                                    (property) =>
                                      property.PropertyTypeId ===
                                      selectedSecondaryProperties[folder][i],
                                  )?.minValue
                                }
                                max={
                                  fileContents[
                                    folder
                                  ]?.SecondaryProperties?.find(
                                    (property) =>
                                      property.PropertyTypeId ===
                                      selectedSecondaryProperties[folder][i],
                                  )?.maxValue
                                }
                                value={
                                  secondaryInputValues[folder]?.[i] ||
                                  fileContents[
                                    folder
                                  ]?.SecondaryProperties?.find(
                                    (property) =>
                                      property.PropertyTypeId ===
                                      selectedSecondaryProperties[folder][i],
                                  )?.maxValue
                                }
                                onChange={(newValue) =>
                                  handleSecondaryInputChange(
                                    folder,
                                    i,
                                    newValue,
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                    },
                  )}
                </div>
              )}
            </>
          }
          title={folder}
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
        >
          {images[selectedIcons[folder]] ? (
            <div style={{ position: 'relative' }}>
              <img
                src={images[selectedIcons[folder]]}
                alt={folder}
                onLoad={() => setImageLoaded(true)}
                onError={() => setImageLoaded(false)}
                style={{
                  zIndex: images[selectedIcons[folder]] ? 1 : -1,
                  height: height,
                  width: width,
                  marginLeft: marginLeft,
                  marginTop: marginTop,
                  objectFit: 'cover',
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
              />
            </div>
          ) : (
            <div
              style={{
                zIndex: images[selectedIcons[folder]] ? 2 : 2,
                position: 'absolute',
                top:
                  folder === 'Ring1' || folder === 'Ring2'
                    ? '35%'
                    : folder === 'Necklace'
                      ? '40%'
                      : folder === 'Head'
                        ? '30%'
                        : folder === 'Legs'
                          ? '50%'
                          : folder === 'Back'
                            ? '40%'
                            : folder === 'Hands'
                              ? '40%'
                              : folder === 'Foot'
                                ? '42%'
                                : folder === 'Chest'
                                  ? '45%'
                                  : folder === 'Secondary' ||
                                      folder === 'Secondary2'
                                    ? '42%'
                                    : folder === 'Primary' ||
                                        folder === 'Primary2'
                                      ? '42%'
                                      : '45%',
                left: '50%',
                marginLeft:
                  folder === 'Primary'
                    ? '-10%'
                    : folder === 'Secondary'
                      ? '-35%'
                      : folder === 'Secondary2'
                        ? '-20%'
                        : folder === 'Head'
                          ? '-5%'
                          : folder === 'Foot'
                            ? '10%'
                            : folder === 'Hands'
                              ? '-10%'
                              : folder === 'Back'
                                ? '10%'
                                : folder === 'Chest'
                                  ? '0'
                                  : folder === 'Legs'
                                    ? '0'
                                    : folder === 'Necklace'
                                      ? '0%'
                                      : folder === 'Ring1'
                                        ? '-8%'
                                        : folder === 'Ring2'
                                          ? '7%'
                                          : '0',

                transform: 'translate(-50%, -50%)',
                height:
                  folder === 'Ring1' || folder === 'Ring2'
                    ? '70%'
                    : folder === 'Head'
                      ? '105%'
                      : folder === 'Foot'
                        ? '83%'
                        : folder === 'Hands'
                          ? '83%'
                          : folder === 'Back'
                            ? '83%'
                            : folder === 'Chest'
                              ? '85%'
                              : folder === 'Legs'
                                ? '100%'
                                : folder === 'Secondary' ||
                                    folder === 'Secondary2'
                                  ? '90%'
                                  : folder === 'Necklace'
                                    ? '70%'
                                    : folder === 'Primary' ||
                                        folder === 'Primary2'
                                      ? '90%'
                                      : '60%',
                width:
                  folder === 'Necklace'
                    ? '46%'
                    : folder === 'Legs'
                      ? '90%'
                      : folder === 'Chest'
                        ? '90%'
                        : folder === 'Head'
                          ? '130%'
                          : folder === 'Back' ||
                              folder === 'Hands' ||
                              folder === 'Foot'
                            ? '90%'
                            : folder === 'Secondary' || folder === 'Secondary2'
                              ? '110%'
                              : folder === 'Primary' || folder === 'Primary2'
                                ? '70%'
                                : '60%',
                backgroundColor: 'transparent',
              }}
            />
          )}
        </Popover>
      </div>
    );
  },
);

FileSelect.displayName = 'FileSelect';
FileSelect.propTypes = {
  folder: PropTypes.string.isRequired,
  style: PropTypes.object,
  setAllPrimaryProperties: PropTypes.func.isRequired,
  allPrimaryProperties: PropTypes.object.isRequired,
  selectedFiles: PropTypes.object.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
};
export default FileSelect;
