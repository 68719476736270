import React from 'react';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const QuestNotConfirmedWarningIcon = () => {
  return (
    <Tooltip
      title={
        'This quest or related data has been detected in the files but has not yet been confirmed in-game. Please be aware that it may be subject to change.'
      }
    >
      <ExclamationCircleOutlined
        style={{ color: 'orange', fontSize: '16px' }}
      />
    </Tooltip>
  );
};

export default QuestNotConfirmedWarningIcon;
